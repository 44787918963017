var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "currentBranch" },
    [
      _vm.hasBranch
        ? _c(
            "div",
            { staticClass: "currentBranch__inner" },
            [
              _c(
                "LinkWrap",
                {
                  staticClass: "currentBranch__name",
                  class: _vm.isCompanyColor,
                  attrs: { link: _vm.branchLink },
                },
                [
                  _vm._v(
                    "\n                persona service " +
                      _vm._s(_vm.branchInternal.name) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "currentBranch__text" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.branchInternal.address)),
                _c("br"),
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.addressCity) + "\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "currentBranch__text" }, [
                _vm.hasPhone
                  ? _c(
                      "span",
                      { staticClass: "currentBranch__icon" },
                      [
                        _c(
                          "link-wrap",
                          { attrs: { link: _vm.branchInternal.phone } },
                          [
                            _c("icon", { attrs: { icon: "phone" } }),
                            _vm._v(
                              _vm._s(
                                _vm._f("phoneNumber")(_vm.branchInternal.phone)
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasFax
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "currentBranch__icon currentBranch__icon--fax",
                      },
                      [
                        _c("icon", { attrs: { icon: "fax" } }),
                        _vm._v(
                          _vm._s(
                            _vm._f("phoneNumber")(_vm.branchInternal.fax)
                          ) + "\n                "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasMail
                  ? _c(
                      "span",
                      { staticClass: "currentBranch__icon" },
                      [
                        _c("icon", { attrs: { icon: "mail" } }),
                        _c(
                          "mail-wrap",
                          {
                            attrs: {
                              address: _vm.branchInternal.email,
                              tag: "span",
                            },
                          },
                          [_vm._v(_vm._s(_vm.branchInternal.email) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "currentBranch__icon currentBranch__icon--opening",
                  },
                  [
                    _c("icon", { attrs: { icon: "watch" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "currentBranch__opening" },
                      _vm._l(_vm.mergeOpeningHours(), function (opening) {
                        return _c("span", { key: opening.days }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(opening.days) },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(opening.label) },
                          }),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm._t("button", function () {
                return [
                  _vm.hasButton
                    ? _c(
                        "btn",
                        {
                          staticClass: "currentBranch__button",
                          attrs: { href: _vm.btnLink },
                        },
                        [_vm._v(_vm._s(_vm.$t("branch.btn.toBranch")))]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass:
                "currentBranch__inner currentBranch__inner--noBranch",
            },
            [
              _c(
                "span",
                {
                  staticClass: "currentBranch__name",
                  class: _vm.isCompanyColor,
                },
                [_vm._v(_vm._s(_vm.$t("branch.notFound.title")))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "currentBranch__text" }, [
                _vm._v(_vm._s(_vm.$t("branch.notFound.desc"))),
              ]),
            ]
          ),
      _vm._v(" "),
      _vm.showFindOther || !_vm.hasBranch
        ? _c(
            "link-wrap",
            {
              staticClass: "currentBranch__link currentBranch__link--underline",
              attrs: { link: _vm.branchSearchLink, type: "js" },
              on: { click: _vm.openSelectBranchPage },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("branch.btn.findAnother")) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }