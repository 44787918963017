/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.17 at 12:28
 */

import {isFunction, isPlainObject, isString, merge} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isNumeric} from '@labor-digital/helferlein/lib/Types/isNumeric';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {FrameworkEventList} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/FrameworkEventList';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {AppStoreKeys} from '../AppStoreKeys';
import {JobDemand} from '../Interface/JobDemand';
import {BranchAndGeoLocationService} from './BranchAndGeoLocationService';
import {FbPixelService} from './FbPixelService';

export interface JobDemandWatcher
{
    (key: string, n: any, o: any, demand: JobDemand): void;
}

export interface JobDemandOptions
{
    page?: number;
    pageSize?: number;
    useAdvancedQuery?: boolean;
}

export class JobService
{

    /**
     * The app context we use to access the store
     */
    protected static _context: AppContext;

    /**
     * A runtime cache to hold values
     */
    protected static _cache: PlainObject;

    /**
     * Internal helper to temporarily disable the watchers of the demand object
     * @protected
     */
    protected static _disableWatchers: boolean;

    /**
     * Initializes the service by injecting the context
     * @param context
     */
    public static initialize(context: AppContext)
    {
        this._context = context;
        this._cache = {};

        // Initialize the demand
        this._context.store.set(AppStoreKeys.JOB_FILTER_DEMAND, {
            sorting: 'relevance',
            query: null,
            advancedQuery: {
                requiredWords: null,
                forbiddenWords: null
            },
            location: null,
            radius: 25,
            start: null,
            end: null,
            contractType: null,
            employmentType: null,
            occupation: null
        });

        // Initialize the shared state
        this._context.store.set(AppStoreKeys.JOB_FILTER_STATE, {
            query: {
                // Helper to show a query string for serp pages, that is not actually used in the demand object
                simulated: null,
                showSpinner: false,
                tooShort: true
            },
            location: {
                items: [
                    BranchAndGeoLocationService.getCurrentLocation()
                ],
                showSpinner: false,
                tooShort: true
            }
        });

        // Reset the count when the demand was updated
        this.watchDemand(() => {
            this._cache.count = undefined;
        });

        // Reset the count if the language changed
        this._context.eventEmitter.bind(FrameworkEventList.EVENT_LANGUAGE_CHANGED, () => {
            this._cache.count = undefined;
        });
    }

    /**
     * Returns the reference on the app context object
     */
    public static get context(): AppContext
    {
        return this._context;
    }

    /**
     * External access to our demand object used by all filter input fields
     */
    public static get state(): PlainObject
    {
        return this._context.store.get(AppStoreKeys.JOB_FILTER_STATE);
    }

    /**
     * External access to our demand object
     */
    public static get demand(): JobDemand
    {
        return this._context.store.get(AppStoreKeys.JOB_FILTER_DEMAND);
    }

    /**
     * Returns the list of all contract types
     */
    public static get contractTypes(): Array<{ label: string, value: string }>
    {
        return this._context.pageContext.commonElements.jobRelations.contractTypes;
    }

    /**
     * Returns the list of all job occupations
     */
    public static get jobOccupations(): Array<{ label: string, value: string }>
    {
        return this._context.pageContext.commonElements.jobRelations.occupations;
    }

    /**
     * Returns the list of all job employment types
     */
    public static get employmentTypes(): Array<{ label: string, value: string }>
    {
        return this._context.pageContext.commonElements.jobRelations.employmentTypes;
    }

    /**
     * Lookup helper to receive a list of jobs based on the currently set demand object
     * @param options
     */
    public static getDemandedJobs(options: JobDemandOptions): Promise<Collection>
    {
        return debouncePromise('jobServiceGetDemandedJobs', () => {
            this._cache.count = undefined;
            return this.getJobsForDemandObject(this.demand, options)
                       .then((response: Collection) => {
                           this._cache.count = response.pagination.itemCount as number;
                           return response;
                       });
        }, 500, true);
    }

    /**
     * Similar to getDemandedJobs() but also works with a custom demand object, instead of using the
     * global demand object.
     *
     * @param demand
     * @param options
     */
    public static getJobsForDemandObject(demand: JobDemand, options?: JobDemandOptions): Promise<Collection>
    {
        options = options ?? {};
        const page = isNumeric(options.page) ? options.page : 1;

        if (page === 1) {
            // Track all demand actions but only on the first page
            FbPixelService.track('Search');
        }

        return this.context
                   .resourceApi
                   .getCollection('job',
                       {
                           filter: this.convertDemandToApiFilter(demand),
                           sort: ['-' + (demand.sorting ?? 'relevance')],
                           page: {
                               number: page,
                               size: options.pageSize ?? 15
                           }
                       });
    }

    /**
     * Merges two demand objects into a new, third object without polluting the first two
     * @param demandA
     * @param demandB
     */
    public static mergeDemands(demandA: JobDemand, demandB: JobDemand): JobDemand
    {
        return merge({}, demandA, demandB) as any;
    }

    /**
     * Extends the global job demand object with the given demand object.
     * The result is the sum of both demands as a new object
     *
     * @param demandToMerge
     */
    public static extendDemand(demandToMerge: JobDemand): JobDemand
    {
        let preparedDemand = {};
        forEach(demandToMerge, (demand, key) => {
            if (isEmpty(preparedDemand[key])) {
                preparedDemand[key] = key === 'advancedQuery' ? demand : [];
            }

            if (isString(demand)) {
                preparedDemand[key] = demand;
                return;
            }

            if (key === 'location') {
                preparedDemand[key] = demand;
                return;
            }

            forEach(demand, item => {
                switch (key) {
                    case 'advancedQuery': {
                        break;
                    }
                    case 'contractType' || 'employmentType': {
                        preparedDemand[key].push({
                            checked: true,
                            label: item,
                            show: true,
                            value: item
                        });
                        break;
                    }
                    default: {
                        preparedDemand[key].push(item);
                    }
                }
            });
        });

        const newDemand = this.mergeDemands(this.demand, preparedDemand);
        this._disableWatchers = true;
        this.context.store.set(AppStoreKeys.JOB_FILTER_DEMAND, newDemand);
        this.context.vue.$nextTick(() => this._disableWatchers = false);
        return newDemand;
    }

    /**
     * Helper to initialize the special state for the SERP display of the job list
     * @param serpsDemand
     * @param searchLabel
     */
    public static initializeSerpsPage(serpsDemand: JobDemand, searchLabel?: string): void
    {
        if (!isPlainObject(serpsDemand) || this.state.isSerps) {
            return;
        }

        // Update the location list
        if (serpsDemand.location) {
            const loc = this.state.location;
            loc.tooShort = false;
            loc.items = [serpsDemand.location];
        }

        // Set a simulated query if required
        if (isString(searchLabel)) {
            this.state.query.simulated = searchLabel;
        }

        this.extendDemand(serpsDemand);

        // On any change in the demand -> leave the serps page
        this.state.serpsUnbinder = this.watchDemand((key) => {
            // We only listen to the relevant keys for serp pages
            if (['occupation', 'contractType', 'employmentType', 'query', 'location'].indexOf(key) === -1) {
                return;
            }
            this.leaveSerpsPage(true);
        });

        this.state.isSerps = true;
    }

    /**
     * If the user is currently viewing a serps page, this method will clean up the
     * demand and redirect the user to the default job listing page
     */
    public static leaveSerpsPage(redirectToJobList: boolean, dropSimulatedQuery?: boolean)
    {
        const state = this.state;
        if (state.isSerps) {
            // Disable serps
            state.isSerps = false;

            // Unbind the listener
            if (isFunction(state.serpsUnbinder)) {
                state.serpsUnbinder();
                state.serpsUnbinder = null;
            }

            // Remove all serps related settings from the demand and state
            const overrideDemand: JobDemand = {advancedQuery: null};
            if (state.query.simulated) {
                if (!dropSimulatedQuery) {
                    overrideDemand.query = state.query.simulated;
                }
                state.query.simulated = null;
            }
            this.extendDemand(overrideDemand);

            // Leave the page
            if (redirectToJobList) {
                this._context.pageContext.linkRepository.goTo('jobList');
            }
        }
    }

    /**
     * Returns the number of results based on the current demand object
     */
    public static getDemandedCount(): Promise<number>
    {
        if (this._cache.count !== undefined) {
            return Promise.resolve(this._cache.count);
        }
        return debouncePromise('jobServiceGetDemandedCount', () => {
            return this.context
                       .resourceApi
                       .getCollection('job',
                           {
                               filter: this.convertDemandToApiFilter(this.demand),
                               page: {
                                   size: 1
                               }
                           })
                       .then((response: Collection) => {
                           return this._cache.count = response.pagination.itemCount as number;
                       });
        }, 500);
    }

    /**
     * Shortcut to register a new listener for changes on the demand object
     * @param listener
     */
    public static watchDemand(listener: JobDemandWatcher): Function
    {
        const store = this._context.store;
        const demand = store.get(AppStoreKeys.JOB_FILTER_DEMAND);
        const unbinders = [];
        forEach(demand, (v, key) => {
            const propertyWatcher = (n, o) => {
                if (!this._disableWatchers && JSON.stringify(n) !== JSON.stringify(o)) {
                    listener(key, n, o, demand);
                }
            };
            store.watch(AppStoreKeys.JOB_FILTER_DEMAND + '.' + key, propertyWatcher);
            unbinders.push(() => {
                store.unbind('a', propertyWatcher);
            });
        });
        const demandWatcher = (n, o) => {
            if (JSON.stringify(n) !== JSON.stringify(o)) {
                listener('@root', n, o, demand);
            }
        };
        store.watch(AppStoreKeys.JOB_FILTER_DEMAND, demandWatcher);
        return () => {
            forEach(unbinders, unbinder => {
                store.unbind('a', demandWatcher);
                unbinder();
            });
        };
    }

    /**
     * Concatenates a list of values into a delimited string
     * @param list
     */
    public static concatValueList(list: Array<string>): string | null
    {
        return (isArray(list) && list.length > 0) ? list.join('___') : null;
    }

    /**
     * Is used to convert the demand object into an api compatible filter object
     *
     * @param demand
     */
    protected static convertDemandToApiFilter(demand: JobDemand): PlainObject
    {
        let filter = {};

        forEach(demand, (value, key) => {
            if (key === 'advancedQuery') {
                forEach(value, (v, k) => {
                    if (!isEmpty(v)) {
                        filter[k] = this.concatValueList(v);
                    }
                });
                return;
            }

            if (isEmpty(value) || key === 'sorting') {
                return;
            }

            if (!isArray(value)) {
                filter[key] = value;
                return;
            }

            const flatValues = [];
            forEach(value, (childValue) => {
                if (isString(childValue)) {
                    flatValues.push(childValue);
                } else if (childValue.value) {
                    flatValues.push(childValue.value);
                }
            });

            filter[key] = this.concatValueList(flatValues);
        });


        return filter;
    }
}
