var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "infinityLoadingSpinner" }, [
    _c(
      "div",
      { staticClass: "infinityLoadingSpinner__container" },
      [_c("spinner", { staticClass: "infinityLoadingSpinner__spinner" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }