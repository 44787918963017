
































import Button from '../../../ContentElement/Persona/Button/Button.vue';
import InputField from '../../Form/InputField/InputField.vue';
import GreenContainer from '../../Layout/GreenContainer/GreenContainer.vue';
import Btn from '../../Misc/Btn/Btn.vue';
import ImageTag from '../../Misc/Image/ImageTag/ImageTag.vue';
import Modal from '../../Modal/Modal/Modal.vue';
import JobAlertModal from '../JobAlertModal/JobAlertModal.vue';

export default {
    name: 'JobMailTeaser',
    components: {JobAlertModal, Btn, Button, GreenContainer, ImageTag, InputField, Modal},
    props: {
        btnIdentifier: String,
        headline: String,
        subtitle: String,
        text: String,
        cta: String,
        hasInput: {
            default: false,
            type: Boolean
        }
    },
    data()
    {
        return {
            email: '',
            isOpen: false
        };
    },
    computed: {
        internalHeadline(): string
        {
            return this.headline ?? this.$t('job.mailteaser.headline');
        },
        internalSubtitle(): string
        {
            return this.subtitle ?? this.$t('job.mailteaser.subtitle');
        },
        internalText(): string
        {
            return this.text ?? this.$t('job.mailteaser.text');
        },
        internalCta(): string
        {
            return this.cta ?? this.$t('job.mailteaser.cta');
        }
    }
};
