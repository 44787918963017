var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "occupation jobFilterSection jobFilterSection--occupation" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _c("icon", {
            staticClass: "jobFilter__icon",
            attrs: { icon: "briefcase" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("jobFilter.form.occupation.label")) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("select-box", {
        attrs: {
          id: _vm.id,
          items: _vm.items,
          placeholder: _vm.$t("jobFilter.form.occupation.placeholder") + " *",
          "error-message": _vm.error,
          readonly: _vm.readonly,
        },
        on: { input: _vm.onInput },
        nativeOn: {
          click: function ($event) {
            return function () {}.apply(null, arguments)
          },
        },
        model: {
          value: _vm.occupation,
          callback: function ($$v) {
            _vm.occupation = $$v
          },
          expression: "occupation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }