
























/**
 * @deprecated Avoid using this component in the future.
 * Use LinkWrap instead, it handles e-mail addresses as well now
 */
export default {
    name: 'MailWrap',
    props: {
        address: String,
        tag: {
            type: String,
            default: 'div'
        }
    },
    methods: {
        onClick()
        {
            window.location.href = 'mailto:' + this.address;
        }
    }
};
