var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "main",
      staticClass: "appSidebarContainer",
      class: _vm.classes,
      attrs: { id: "app-main-scroll-container" },
    },
    [
      _c("fixed-header", {
        staticClass: "appSidebarContainer__fixedHeader",
        attrs: { context: _vm.context },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "appSidebarContainer__rail",
          style: _vm.styleRailContainer,
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.loadComponent
            ? _c("fixed-action-buttons", { attrs: { context: _vm.context } })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadComponent
            ? _c("fixed-to-top-button", { attrs: { context: _vm.context } })
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.isOverlayShown
              ? _c("div", {
                  staticClass: "appSidebarContainer__overlay",
                  on: { click: _vm.onOverlayClick },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.loadComponent
            ? _c("fixed-action-button-slide-in-menu", {
                attrs: { context: _vm.context },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "left",
              staticClass: "appSidebarContainer__left",
              style: _vm.styleLeftSidebar,
            },
            [
              _vm.leftComponent !== null
                ? _c(_vm.leftComponent, {
                    tag: "component",
                    attrs: { context: _vm.context },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobile
            ? _c(
                "div",
                {
                  ref: "right",
                  staticClass: "appSidebarContainer__right",
                  style: _vm.styleRightSidebar,
                },
                [
                  _vm.isMobile
                    ? _c("main-menu-mobile", {
                        attrs: { context: _vm.context },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isSearchVisible
        ? _c("search", { attrs: { context: _vm.context } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }