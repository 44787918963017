




































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {JobService} from '../../../../Service/JobService';
import Checkbox from '../../../Form/Checkbox/Checkbox.vue';
import SelectBox from '../../../Form/SelectBox/SelectBox.vue';
import Icon from '../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../../JobFilter/Type/JobFilterMixin';

export default {
    name: 'JobAlertOccupation',
    components: {SelectBox, Checkbox, Icon},
    mixins: [JobFilterMixin],
    props: {
        isMultiselect: true,
        error: String,
        readonly: false
    },
    data()
    {
        return {
            occupation: null
        };
    },
    computed: {
        items(): Array<{ label: string, value: string }>
        {
            return JobService.jobOccupations;
        }
    },
    methods: {
        onInput(val)
        {
            this.$emit('input', isEmpty(val) ? null : val);
        }
    }
};
