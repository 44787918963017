






























import Icon from '../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../Misc/Icon/IconHoverMixin';
import ShareModal from '../../Modal/ShareModal/ShareModal.vue';

export default {
    name: 'ShareButton',
    components: {ShareModal, Icon},
    mixins: [IconHoverMixin],
    props: {
        title: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'job'
        }
    },
    data()
    {
        return {
            modalOpen: false
        };
    },
    methods: {
        onClick()
        {
            this.modalOpen = true;
        }
    }
};
