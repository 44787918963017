






































import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppEventKeys} from '../../../../AppEventKeys';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import {SearchResult} from '../../../../Interface/SearchResult';
import ImageTag from '../../../Misc/Image/ImageTag/ImageTag.vue';

export default {
    name: 'List',
    components: {ImageTag, LinkWrap},
    props: {
        context: {
            type: Object,
            required: true
        } as PageContext,
        items: {
            type: Array,
            required: true
        }
    },
    methods: {
        onElementClick()
        {
            this.context.eventEmitter.emit(AppEventKeys.CLOSE_SEARCH_DROPDOWN);
        },
        getText(item: SearchResult)
        {
            if (item.contentMatch && item.contentMatch.length > 0) {
                return item.contentMatch.replace(new RegExp('\\[match\\](.*?)\\[/match\\]', 'gi'), '<strong>$1</strong>');
            }

            return item.description;
        }
    },
    computed: {
        listItems(): Array<SearchResult>
        {
            return this.items;
        }
    }
};
