















































































































import {asArray} from '@labor-digital/helferlein/lib/FormatAndConvert/asArray';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isNumeric} from '@labor-digital/helferlein/lib/Types/isNumeric';
import {stopBodyScrolling} from '@labor-digital/helferlein/lib/Ui/stopBodyScrolling';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {Collection, Resource} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {AppEventKeys} from '../../../AppEventKeys';
import {AppStoreKeys} from '../../../AppStoreKeys';
import EmptyListNotification from '../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import Tabs from '../../../Component/Misc/Tabs/Tabs.vue';
import {JobService} from '../../../Service/JobService';
import InputField from '../../Form/InputField/InputField.vue';
import JobItemList from '../../Job/JobItemList/JobItemList.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import InfinityLoadingSpinner from '../../Misc/Spinner/InfinityLoadingSpinner.vue';
import Logo from '../FixedHeader/Components/Logo/Logo.vue';
import GreenContainer from '../GreenContainer/GreenContainer.vue';
import Featured from './Featured/Featured.vue';
import List from './List/List.vue';

export default {
    name: 'Search',
    components: {
        GreenContainer,
        Featured,
        List,
        InputField,
        Logo,
        Icon,
        Tabs,
        LinkWrap,
        EmptyListNotification,
        InfinityLoadingSpinner,
        JobItemList
    },
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            isOpen: false,
            isSearching: false,
            showFeatured: true,
            countNeeded: true,
            jobServiceQueryBackup: null,
            query: '',
            page: 0,
            pages: 2,
            infiniteId: 1,
            result: [],
            tabIndex: 0,
            jobTabIndex: 3,
            tabs: [
                {
                    label: this.$t('search.tabs.all'),
                    tag: '@total',
                    count: 0
                },
                {
                    label: this.$t('search.tabs.pages'),
                    tag: 'page',
                    count: 0
                },
                {
                    label: this.$t('search.tabs.faq'),
                    tag: 'faq',
                    count: 0
                },
                {
                    label: this.$t('search.tabs.jobs'),
                    tag: 'jobs',
                    count: 0
                },
                {
                    label: this.$t('search.tabs.magazine'),
                    tag: 'article',
                    count: 0
                },
                {
                    label: this.$t('search.tabs.newsAndEvents'),
                    tag: 'newsAndEvent',
                    count: 0
                }
            ]
        };
    },
    methods: {
        toggle(toggle)
        {
            this.isOpen = toggle;
            this.context.store.set(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, toggle);
            this.context.store.set(AppStoreKeys.SEARCH_OVERLAY_IS_VISIBLE, toggle);
            this.$nextTick(() => {
                stopBodyScrolling(toggle);
            });
        },
        open()
        {
            if (this.isOpen) {
                return;
            }
            this.jobServiceQueryBackup = JobService.demand.query + '';
            this.toggle(true);
            this.$nextTick(() => {
                document.querySelectorAll<HTMLElement>('#search-query input')[0].focus();
            });
        },
        close()
        {
            if (!this.isOpen) {
                return;
            }
            if (this.jobServiceQueryBackup !== null
                && JobService.demand.query + '' !== this.jobServiceQueryBackup) {
                JobService.demand.query = this.jobServiceQueryBackup !== 'null' ? this.jobServiceQueryBackup : null;
            }
            this.jobServiceQueryBackup = null;
            this.toggle(false);
        },
        onListElementClick()
        {
            this.close();
        },
        onSearchClick()
        {
            this.close();
        },
        onTabChange(index: number)
        {
            this.tabIndex = index;
            this.resetInfiniteScrolling();
        },
        onInfinite(state)
        {
            if ((this.query + '').length < 3) {
                return;
            }

            if (this.page >= this.pages) {
                state.complete();
                return;
            }

            this.page += 1;

            this.search().then(() => {
                if (this.result.length === 0) {
                    state.complete();
                } else {
                    state.loaded();
                }
            });
        },
        search(): Promise<boolean>
        {
            this.isSearching = true;
            this.showFeatured = false;

            let promises = [
                this.tabIndex === this.jobTabIndex ? this.getJobResults() : this.getResults()
            ];
            if (this.countNeeded) {
                promises.push(
                    this.countResults(),
                    this.countJobResults()
                );
            }

            return Promise
                .all(promises)
                .then(() => {
                    this.isSearching = false;
                    this.countNeeded = false;

                    return Promise.resolve(true);
                });
        },
        getResults(): Promise<Collection>
        {
            return this.context.resourceApi.getCollection('search', {
                // @todo Do we need maxTagItems here? Add a global "limit" variable?
                query: this.query,
                // Search for a specific tag if its not the "all" tab
                tags: (this.tabs[this.tabIndex].tag !== '@total') ? this.tabs[this.tabIndex].tag : null,
                page: {
                    number: isNumeric(this.page) ? this.page : 1
                }
            }).then(result => {
                this.result = this.result.concat(asArray(map(result, (item: Resource) => item.getAll())));
                this.page = result.pagination.page;
                this.pages = result.pagination.pages;
            });
        },
        getJobResults(): Promise<Collection>
        {
            return JobService.getDemandedJobs({page: this.page}).then(jobs => {
                this.result = this.result.concat(asArray(map(jobs as any, (job: Resource) => job.getAll())));
                this.pages = jobs.pagination.pages;
                this.page = jobs.pagination.page;
            });
        },
        countResults(): Promise<Collection>
        {
            return this.context.resourceApi.getAdditional('search', 'count', {
                query: this.query
            }).then(result => {
                forEach(result, (count, tag) => {
                    let matchedTab = this.tabs.filter(tab => {
                        return tab.tag === tag;
                    });

                    if (matchedTab.length) {
                        matchedTab[0].count = count;
                    }
                });
            });
        },
        countJobResults(): Promise<Collection>
        {
            return JobService.getDemandedCount().then(count => {
                this.tabs[this.jobTabIndex].count = count;
            });
        },
        resetInfiniteScrolling()
        {
            this.page = 0;
            this.result = [];
            this.infiniteId += 1;
        },
        getTabCount(item)
        {
            let count = this.tabs.filter(tab => {
                return tab.label === item.label;
            })[0].count;

            // Add job count to all tab manually
            if (item.id === 0) {
                count += this.tabs[this.jobTabIndex].count;
            }

            return count;
        }
    },
    created(): void
    {
        this.context.eventEmitter.bind(AppEventKeys.CLOSE_SEARCH_DROPDOWN, this.onListElementClick);

        this.$watch(() => this.query, () => {
            this.showFeatured = true;
            this.countNeeded = true;

            forEach(this.tabs, (tab) => {
                tab.count = 0;
            });

            if ((this.query + '').length < 3) {
                return;
            }

            // This triggers the actual search
            debouncePromise('searchInput', () => {
                return Promise.resolve();
            }, 300, true).then(() => {
                if (!this.isSearching) {
                    JobService.demand.query = this.query;
                    this.resetInfiniteScrolling();
                }
            });
        });
    },
    computed: {
        showSpinner(): boolean
        {
            return this.isSearching;
        }
    },
    mounted()
    {
        this.open();
    },
    beforeDestroy()
    {
        this.context.eventEmitter.unbind(AppEventKeys.CLOSE_SEARCH_DROPDOWN, this.onListElementClick);
        this.close();
    }
};
