


























import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import LinkWrap from '../LinkWrap/LinkWrap.vue';

export default {
    name: 'LinkCloud',
    components: {LinkWrap},
    props: {
        links: Array
    },
    computed: {
        isEmpty(): boolean
        {
            return !isEmpty(this.links);
        }
    }
};
