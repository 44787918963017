var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        directives: [
          {
            name: "button-active-gradient",
            rawName: "v-button-active-gradient:[isGhost]",
            value: false,
            expression: "false",
            arg: _vm.isGhost,
          },
        ],
        staticClass: "btn",
        class: _vm.classes,
        on: {
          mouseenter: function ($event) {
            _vm.isHover = true
          },
          mouseleave: function ($event) {
            _vm.isHover = false
          },
        },
      },
      "div",
      _vm.iconHoverContainerProps(),
      false
    ),
    [
      _c(
        "link-wrap",
        {
          staticClass: "btn__label",
          attrs: {
            id: _vm.identifier,
            type: _vm.linkType,
            link: _vm.href,
            "is-disabled": _vm.isDisabled,
            title: _vm.makeTitle(),
          },
        },
        [
          _vm.useIconComponent && _vm.hasIconLeft
            ? _c(
                "icon",
                _vm._b(
                  {
                    staticClass: "btn__icon btn__icon--rightMargin",
                    attrs: { icon: _vm.icon },
                  },
                  "icon",
                  _vm.iconHoverProps(),
                  false
                )
              )
            : _vm.hasIconLeft
            ? _c("span", {
                staticClass: "btn__icon btn__icon--rightMargin",
                style: _vm.styles,
                domProps: { innerHTML: _vm._s(_vm.iconSvgContent) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.iconPosition !== "withoutLabel"
            ? _vm._t("default", function () {
                return [
                  _vm._v("\n            " + _vm._s(_vm.label) + "\n        "),
                ]
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.useIconComponent && _vm.hasIconRight
            ? _c(
                "icon",
                _vm._b(
                  {
                    staticClass: "btn__icon btn__icon--leftMargin",
                    attrs: { icon: _vm.icon },
                  },
                  "icon",
                  _vm.iconHoverProps(),
                  false
                )
              )
            : _vm.hasIconRight
            ? _c("span", {
                staticClass: "btn__icon btn__icon--leftMargin",
                style: _vm.styles,
                domProps: { innerHTML: _vm._s(_vm.iconSvgContent) },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }