/*
 * Copyright 2023 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2023.04.12 at 10:33
 */

export class ScrollThrottle
{
    private _scrolling: boolean = false;
    private _scrollTimeout: number = 0;
    private _scrollHandler: (event: Event) => void;

    constructor(scrollHandler: (event: Event) => void)
    {
        this._scrollHandler = scrollHandler;
    }

    public start(): void
    {
        window.addEventListener('scroll', this._scrollHandler);
    }

    public stop(): void
    {
        window.removeEventListener('scroll', this._scrollHandler);
    }

    public scrollHandler(event: Event): void
    {
        if (!this._scrolling) {
            this._scrolling = true;
            this._scrollHandler(event);
        }

        window.clearTimeout(this._scrollTimeout);
        this._scrollTimeout = window.setTimeout(() => {
            this._scrolling = false;
        }, 66);
    }
}
