






























import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import {MenuService} from '../../../Service/MenuService';
import Icon from '../../Misc/Icon/Icon.vue';
import HomeLinkWrap from '../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'Breadcrumb',
    components: {HomeLinkWrap, LinkWrap, Icon},
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            key: 0
        };
    },
    computed: {
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages() || MenuService.isLandingpage;
        },
        rootLine(): Array<PlainObject>
        {
            // Helper to force the root line re-rendering
            this.key;

            // Filter out all invalid root line elements
            const rootLineClean = [];
            const pidRepository = this.context.pidRepository;
            const invalidPids = [
                pidRepository.getPid('page.home'),
                pidRepository.getPid('page.homeForCompanies'),
                pidRepository.getPid('page.homeForLandingpages'),
                pidRepository.getPid('storage.meta')
            ];
            forEach(this.context.rootLine, (el: RootLineElementInterface) => {
                if (invalidPids.indexOf(parseInt(el.id as string)) !== -1) {
                    return;
                }
                el.title = isEmpty(el.navTitle) ? el.title : el.navTitle;
                rootLineClean.push(el);
            });

            // @todo still relevant?
            // Inject the potentially updated page title into the breadcrumb
            // if (rootLineClean.length > 0) {
            //     try {
            //         rootLineClean[rootLineClean.length - 1].title =
            //             this.context.pageMeta.vueMeta.refresh().metaInfo.titleChunk;
            //     } catch (e) {
            //         // Ignore errors
            //     }
            // }

            // Reverse the root line
            return rootLineClean;
        }
    },
    mounted()
    {
        setTimeout(() => {
            this.key++;
        }, 500);
    }
};
