var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jobItemList" }, [
    _c(
      "ul",
      { staticClass: "jobItemList__list" },
      [
        _vm.isLoaded && _vm.isMainLanguage
          ? _c(
              "li",
              [
                _c("job-mail-teaser", {
                  attrs: { "btn-identifier": "jobMailTeaserList" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.jobs, function (job, index) {
          return _c(
            "li",
            _vm._b(
              { staticClass: "jobItemList__item" },
              "li",
              _vm.iconHoverContainerProps(job.id),
              false
            ),
            [
              _c(
                "a",
                {
                  attrs: { href: job.url },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onJobClick(job)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "jobItemList__itemHeader" }, [
                    _c("h2", { staticClass: "jobItemList__itemTitle" }, [
                      _vm._v(_vm._s(job.title)),
                    ]),
                    _vm._v(" "),
                    !_vm.searchLayout
                      ? _c(
                          "div",
                          {
                            staticClass: "jobItemList__itemActions",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("save-job-action", { attrs: { job: job } }),
                            _vm._v(" "),
                            _c("share-button", {
                              attrs: { title: job.title, url: job.url },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "jobItemList__itemGrid" }, [
                    job.city
                      ? _c(
                          "li",
                          { staticClass: "jobItemList__itemGridItem city" },
                          [
                            _c("icon", { attrs: { icon: "mapPin" } }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(job.city) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.searchLayout && job.contractType
                      ? _c(
                          "li",
                          { staticClass: "jobItemList__itemGridItem" },
                          [
                            _c("icon", { attrs: { icon: "briefcase" } }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(job.contractType) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.searchLayout && job.type
                      ? _c(
                          "li",
                          { staticClass: "jobItemList__itemGridItem" },
                          [
                            _c("icon", { attrs: { icon: "clock" } }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(job.type) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  !_vm.searchLayout && job.teaserText
                    ? _c("p", { staticClass: "jobItemList__listTeaser" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.shortenTeaserText(job.teaserText)) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.searchLayout && job.updated
                    ? _c(
                        "p",
                        { staticClass: "date" },
                        [
                          _c("icon", { attrs: { icon: "pencil" } }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.dateFormatter.formatRelativeDate(
                                  job.updated
                                )
                              ) +
                              "\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "icon",
                    _vm._b(
                      { attrs: { icon: "arrowLeft" } },
                      "icon",
                      _vm.iconHoverProps(job.id),
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }