































import {getPath} from '@labor-digital/helferlein';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppEventKeys} from '../../../../AppEventKeys';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'Featured',
    components: {LinkWrap},
    props: {
        context: {
            type: Object,
            required: true
        } as PageContext
    },
    methods: {
        onElementClick()
        {
            this.context.eventEmitter.emit(AppEventKeys.CLOSE_SEARCH_DROPDOWN);
        }
    },
    computed: {
        items(): Array<Object>
        {
            return getPath(this.context, 'commonElements.config.search.relevantLinks', []);
        }
    }
};
