/*
 * Copyright 2021 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2021.01.11 at 10:21
 */

import {isBrowser as testIsInBrowser, isUndefined} from '@labor-digital/helferlein';

declare global
{
    interface Window
    {
        dataLayer?: Array<any>
    }
}

const isBrowser = testIsInBrowser();

export class GoogleTagManagerService
{
    /**
     * Uses the dynamic content wrapper of gtag to emit an event when the page was changed.
     */
    public static trackPageChange(): void
    {
        if (this.dataLayerLoaded()) {
            setTimeout(() => {
                window.dataLayer.push({'event': 'pageview'});
            }, 150);
        }
    }

    /**
     * Sends custom event to gtag
     * @param event
     * @param value
     */
    public static track(event?: string, value?: string): void
    {
        if (this.dataLayerLoaded()) {
            window.dataLayer.push({'event': event, value: value});
        }
    }

    /*
    *
     */
    protected static dataLayerLoaded(): Boolean
    {
        return isBrowser && !isUndefined(window.dataLayer);
    }
}
