var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Error" }, [
    _c(
      "div",
      { staticClass: "Error__inner" },
      [
        _c("logo"),
        _vm._v(" "),
        _c("h1", [
          _vm._v(
            "An der Seite von persona service wird gerade fleißig gearbeitet! "
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "Bitte entschuldigen Sie die Umstände. Wir sind gleich wieder für Sie da. Bitte\n            schauen Sie zeitnah nochmal vorbei!"
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }