





























export default {
    name: 'GreenContainer',
    props: {
        addContainerStyles: {
            type: Boolean,
            default: true
        }
    }
};
