var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subPageListing" }, [
    _c(
      "nav",
      { staticClass: "subPageListing__index" },
      _vm._l(_vm.items, function (item) {
        return _c("div", { staticClass: "subPageListing__indexItem" }, [
          _c(
            "div",
            { staticClass: "subPageListing__indexItemInner" },
            [
              _c(
                "link-wrap",
                {
                  staticClass: "subPageListing__indexItemLink",
                  attrs: { link: item.href },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.title) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    !_vm.directLinks
      ? _c(
          "div",
          { staticClass: "subPageListing__list" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                ref: item.id,
                refInFor: true,
                staticClass: "subPageListing__listItem",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "subPageListing__listItemPreview",
                    attrs: { to: item.href },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "subPageListing__listItemPreviewWrap" },
                      [
                        _c("div", {
                          staticClass: "subPageListing__listItemPreviewSize",
                        }),
                        _vm._v(" "),
                        item.subPageListing.image === null
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "subPageListing__listItemPreviewInner",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "subPageListing__listItemPreviewGradient",
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "subPageListing__listItemPreviewFont",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.formatFancyText(item)
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "subPageListing__listItemPreviewInner",
                              },
                              [
                                _c("image-tag", {
                                  staticClass:
                                    "subPageListing__listItemPreviewImage",
                                  attrs: {
                                    image: item.subPageListing.image,
                                    definition: "subPageListing",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "subPageListing__listItemContent" },
                  [
                    _c(
                      "div",
                      { staticClass: "subPageListing__listItemCategory" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.title) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "subPageListing__listItemHeadline" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.subPageListing.headline) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "subPageListing__listItemTeaser" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.subPageListing.teaser) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("btn", { attrs: { href: item.href } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            item.subPageListing.ctaLabel === ""
                              ? _vm.$t(
                                  "navigation.subPageList.readMoreFallback"
                                )
                              : item.subPageListing.ctaLabel
                          ) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }