var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "featured" }, [
    _c("h3", [_vm._v(_vm._s(_vm.$t("navigation.search.defaultHeadline")))]),
    _vm._v(" "),
    _vm.items.length > 0
      ? _c(
          "ul",
          { staticClass: "featured__list" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "li",
              { staticClass: "featured__item" },
              [
                _c(
                  "link-wrap",
                  {
                    attrs: { link: item.slug },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onElementClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.title) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }