var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultLayout" },
    [
      _c("layout-header", { attrs: { context: _vm.context } }),
      _vm._v(" "),
      _c("content-element-children", {
        attrs: { col: 0, context: _vm.context },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }