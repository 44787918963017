























import {lcFirst} from '@labor-digital/helferlein/lib/Strings/lcFirst';

export default {
    name: 'Icon',
    props: {
        /**
         * The icon identifier to render
         */
        icon: {
            type: String
        },
        /**
         * True to render the filled icon
         */
        filled: {
            type: Boolean,
            default: false
        },
        /**
         * The color modifier for the icon
         */
        color: {
            type: String,
            default: 'default',
            validator: v => {
                // Only accepts values that contain the string 'default, dark, company'.
                return v === 'default' || v === 'dark' || v === 'company';
            }
        }
    },
    computed: {
        classes()
        {
            return {
                'icon--filled': this.filled,
                ['icon--' + lcFirst(this.icon)]: true,
                ['icon--' + this.color]: this.color !== 'default'
            };
        }
    }
};
