




































import {isBrowser} from '@labor-digital/helferlein/lib/Environment/isBrowser';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {getPath} from '@labor-digital/helferlein/lib/Lists/Paths/getPath';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isPlainObject} from '@labor-digital/helferlein/lib/Types/isPlainObject';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
// 'xxs: 0|350px, xs: 0|575px, sm: 576px|767px, md: 768px|991px, lg: 992px|1199px, xl: 1200px|999999px'
const breakpoints = {
    xxs: 350, xs: 575, sm: 767, md: 991, lg: 1199, xl: 999999
};

export default {
    name: 'ImageTag',
    props: {
        /**
         * The image object to render as an image tag
         */
        image: Object,
        /**
         * A list of definition keys by the breakpoint short code
         * The short code is used as a max-width value
         * so {sm: small} will use the "small" definition for all window width up until
         * the max width of the sm break point. All other breakpoints will fall back to the
         * definition given as "definition"    prop
         */
        bpDefinitions: {
            Type: Object,
            default: null
        },
        /**
         * A imaging definition to process the image with
         * Can be overwritten by the bpDefinitions object
         */
        definition: {
            Type: String,
            default: ''
        },
        /**
         * A crop definition -> By default the "default" variant will be used
         */
        crop: {
            Type: String,
            default: ''
        },
        /**
         * Set to empty or null if not needed
         */
        lazyLoading: {
            Type: String | null,
            default: 'lazy'
        }
    },
    computed: {
        supportsPicture(): boolean
        {
            return !isBrowser() || !!(
                window as any
            ).HTMLPictureElement;
        },
        src(): string
        {
            return this.buildUrl(this.definition);
        },
        sourceList(): Array<PlainObject>
        {
            // Static images are not resolved using the imaging endpoint
            if (this.image.isStatic === true) {
                return [
                    {
                        srcset: this.image.url + ' 1x',
                        sizes: '100vw'
                    }
                ];
            }

            // Prepare source list
            const sources = [];

            // Check if we have breakpoint based definitions
            if (isPlainObject(this.bpDefinitions) && !isEmpty(this.bpDefinitions)) {
                forEach(breakpoints, (definition: number, bpKey: string) => {
                    if (!this.bpDefinitions[bpKey]) {
                        return;
                    }
                    if (this.bpDefinitions[bpKey] === this.definition) {
                        return;
                    }
                    sources.push({
                        'data-definition': this.bpDefinitions[bpKey],
                        srcset: this.buildSrcSet(this.bpDefinitions[bpKey]),
                        media: '(max-width: ' + definition + 'px)',
                        sizes: '100vw'
                    });
                });
            }

            // Build the default source
            sources.push({
                'data-definition': this.definition,
                srcset: this.buildSrcSet(this.definition),
                sizes: '100vw'
            });

            // Done
            return sources;
        },
        alt()
        {
            const v = getPath(this.image, 'image.alt', null);
            if (isEmpty(v)) {
                return null;
            }
            return v;
        },
        title()
        {
            const v = getPath(this.image, 'image.title', null);
            if (isEmpty(v)) {
                return null;
            }
            return v;
        },
        ariaLabel()
        {
            return this.title ?? this.alt;
        },
        fallbackAttr()
        {
            return {
                style: {
                    backgroundImage: 'url(' + this.src + ')'
                },
                class: 'imageTag',
                alt: this.alt,
                title: this.title,
                ariaLabel: this.title ?? this.alt,
                'data-size': JSON.stringify({width: this.image.image.width, height: this.image.image.height})
            };
        },
        attr()
        {
            if (!isPlainObject(this.image) || !isUndefined(this.image.error)) {
                return {class: {'imageTag--missing': true}};
            }
            return {};
        },
        desc()
        {
            if (!isEmpty(this.attr)) {
                return '';
            }
            return getPath(this.image, ['image', 'desc'], '');
        }
    },
    methods: {

        /**
         * Builds the src set for a given definition
         * @param definition
         */
        buildSrcSet(definition: string): string
        {
            const src = this.buildUrl(definition);
            const hasQuery = src.indexOf('?') !== -1;
            return src + ' 1x, ' + src + (
                hasQuery ? '&' : '?'
            ) + 'isX2=true 2x';
        },

        /**
         * Builds the url for a single image with the given definition key
         * @param definition
         */
        buildUrl(definition: string): string
        {
            if (this.image.isStatic === true) {
                return this.image.url;
            }

            let url = this.image.url;
            if (definition !== '') {
                url += '&definition=' + definition;
            }
            if (this.crop !== '') {
                url += '&crop=' + this.crop;
            }
            return url;
        }
    }
};
