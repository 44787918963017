/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.07.02 at 10:00
 */

import {GenericStorage} from '@labor-digital/helferlein/lib/Entities/GenericStorage';
import {asArray} from '@labor-digital/helferlein/lib/FormatAndConvert/asArray';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {getLocalStorage} from '@labor-digital/helferlein/lib/Misc/getLocalStorage';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {AppStoreKeys} from '../AppStoreKeys';
import {Job} from '../Interface/Job';
import {FbPixelService} from './FbPixelService';

interface SavedJob
{
    id: number,
    languageCode: string
}

export class SavedJobService
{
    /**
     * The context we work with
     */
    protected static _context: AppContext;

    /**
     * The storage object to persist the list data in the local storage of the browser
     */
    protected static _localStorage: GenericStorage;

    /**
     * Inject the context and load the job data from the api
     * @param context
     */
    public static initialize(context: AppContext)
    {
        context.store.set(AppStoreKeys.JOB_SAVED_LIST, new Map<number, Job>());
        this._localStorage = getLocalStorage('savedJobs');
        this._context = context;
        this.findInitialState();
    }

    /**
     * Stores a single job offer for later usage
     * @param job
     */
    public static saveJob(job: Job): void
    {
        // Check if we already have this job
        const jobs = this.getSavedJobs();
        if (jobs.has(job.id)) {
            return;
        }

        FbPixelService.track('JobMerken');

        // Store the job
        jobs.set(job.id, job);
        this._context.store.set(AppStoreKeys.JOB_SAVED_LIST, jobs);

        // Persist the job
        const savedJobs: Array<SavedJob> = this._localStorage.get('jobs', []);
        savedJobs.push({id: job.id, languageCode: this._context.pageContext.languageCode});
        this._localStorage.set('jobs', asArray(savedJobs));
    }

    /**
     * Removes a previously saved job from the list
     * @param job
     */
    public static forgetJob(job: Job): void
    {
        // Check if we don't have this job
        const jobs = this.getSavedJobs();
        if (!jobs.has(job.id)) {
            return;
        }

        // Remove the job
        jobs.delete(job.id);
        this._context.store.set(AppStoreKeys.JOB_SAVED_LIST, jobs);

        // Remove the job from the persisted storage
        const savedJobs: Array<SavedJob> =
            this._localStorage.get('jobs', [])
                .filter(v => v.id !== job.id);
        this._localStorage.set('jobs', savedJobs);
    }

    /**
     * Returns the list of saved jobs
     */
    public static getSavedJobs(): Map<number, Job>
    {
        return new Map(this._context.store.get(AppStoreKeys.JOB_SAVED_LIST));
    }

    /**
     * Uses the job list, which is persisted in the local storage
     * to retrieve the additional data for each job from the api
     * and fills the JOB_SAVED_LIST store value with the result
     */
    protected static findInitialState(): Promise<void>
    {
        // Try to load the stored job list
        const storedJobs = this._localStorage.get('jobs', []);
        if (isEmpty(storedJobs)) {
            return;
        }

        // Require the jobs
        this._context.resourceApi.getCollection('job', {
            'filter': {
                'translatedIds': map(storedJobs, (v: SavedJob) => v.id + '.' + v.languageCode)
            }
        }).then(jobs => {
            const jobMap: Map<string, Job> = new Map();
            forEach(jobs, (job) => {
                jobMap.set(job.get('id'), job.getAll() as any);
            });
            this._context.store.set(AppStoreKeys.JOB_SAVED_LIST, jobMap);
        });
    }
}
