





























import BaseModal from '@labor-digital/vuetiful/src/Components/Modal/Modal.vue';
import Icon from '../../Misc/Icon/Icon.vue';

export default {
    name: 'Modal',
    extends: BaseModal,
    components: {BaseModal, Icon},
    props: ['value'],
    computed: {
        emittedValue: {
            get()
            {
                return this.value;
            },
            set(val)
            {
                this.$emit('input', val);
            }
        }
    }
    
};
