



































































import {maxLength} from '@labor-digital/helferlein/lib/Strings/maxLength';
import {Job} from '../../../Interface/Job';
import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import {DateFormatter} from '../../../Service/DateFormatter';
import Icon from '../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../Misc/Icon/IconHoverMixin';
import ShareButton from '../../Misc/ShareButton/ShareButton.vue';
import JobMailTeaser from '../JobMailTeaser/JobMailTeaser.vue';
import SaveJobAction from '../JobMetaActions/SaveJobAction/SaveJobAction.vue';

export default {
    name: 'JobItemList',
    components: {ShareButton, JobMailTeaser, Icon, SaveJobAction},
    mixins: [IconHoverMixin, LanguageSwitchMixin],
    props: {
        jobs: {
            type: Array,
            required: true
        },
        searchLayout: {
            type: Boolean,
            default: false
        },
        isLoaded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dateFormatter(): DateFormatter
        {
            return DateFormatter;
        }
    },
    methods: {
        shortenTeaserText(text: string): string
        {
            return maxLength(text, 250);
        },
        onJobClick(job: Job)
        {
            this.$root.appContext.pageContext.router.push('/' + job.url.replace(/^(?:\/\/|[^/]+)*\//, ''));
        }
    }
};
