




































import {filter} from '@labor-digital/helferlein/lib/Lists/filter';
import BaseInputField from '@labor-digital/vuetiful/src/Components/InputField/InputField';
import Icon from '../../Misc/Icon/Icon.vue';
import Spinner from '../../Misc/Spinner/Spinner.vue';

export default {
    name: 'InputField',
    components: {Spinner, Icon, BaseInputField},
    props: {
        ...BaseInputField.props,
        showSpinner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        slots()
        {
            // Only pass on slots that we don't overwrite
            const overwritten = ['beforeClearIcon', 'before-clear-icon'];
            return filter(this.$scopedSlots, (v, k) => {
                return overwritten.indexOf(v) === -1;
            });
        }
    }
};
