//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'EmptyListNotification',
    props: {
        hasGreenBackground: {
            Type: Boolean,
            default: false
        }
    }
};
