var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.listItems.length > 0
    ? _c(
        "ul",
        { staticClass: "search__list" },
        _vm._l(_vm.listItems, function (item) {
          return _c(
            "li",
            {
              staticClass: "search__item",
              class: { "search__item--hasImage": item.image },
            },
            [
              _c(
                "link-wrap",
                {
                  attrs: { link: item.url },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onElementClick.apply(null, arguments)
                    },
                  },
                },
                [
                  item.image
                    ? _c("image-tag", {
                        attrs: { image: item.image, definition: "search" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.getText(item)) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              item.metaData && item.metaData.rootLine
                ? _c(
                    "ul",
                    _vm._l(item.metaData.rootLine, function (page) {
                      return _c(
                        "li",
                        [
                          _c("router-link", { attrs: { to: page.slug } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(page.title) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }