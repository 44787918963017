
import "core-js/es/array/includes";
import "core-js/features/promise/index.js";
import "core-js/features/set/index.js";
import "core-js/features/map/index.js";
import "core-js/features/object/assign.js";
import "core-js/features/object/entries.js";
import "core-js/features/object/keys.js";
import "core-js/features/array/from.js";
import "core-js/features/symbol/index.js";
import "core-js/features/array/includes.js";
import {errorHandler} from '@appsignal/vue';
import {configureScrollToTopOf, isArray, registerEventScrollThrottled} from '@labor-digital/helferlein';
import {registerEventBreakPointChange} from '@labor-digital/helferlein/lib/Events/DomEvents/registerEventBreakpointChange';
import {registerEventResizeThrottled} from '@labor-digital/helferlein/lib/Events/DomEvents/registerEventResizeThrottled';
import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {configureFormatDateAndTime} from '@labor-digital/helferlein/lib/FormatAndConvert/formatDateAndTime';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {ucFirst} from '@labor-digital/helferlein/lib/Strings/ucFirst';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {BreakpointService} from '@labor-digital/helferlein/lib/Ui/BreakpointService/BreakpointService';
import {getScrollPos} from '@labor-digital/helferlein/lib/Ui/getScrollPos';
import {scrollToTopOf} from '@labor-digital/helferlein/lib/Ui/scrollToTopOf';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {FrameworkEventList} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/FrameworkEventList';
import {SpaApp} from '@labor-digital/typo3-vue-framework/lib/Variants/SpaApp';
import Vue from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import './App.sass';
import {appSignal} from './AppSignal';
import {AppStoreKeys} from './AppStoreKeys';
// @ts-ignore
import AppComponent from './Component/AppComponent.vue';
// @ts-ignore
import ErrorComponent from './Component/ErrorComponent.vue';
// @ts-ignore
import {ButtonActiveGradient} from './Directive/ButtonActiveGradient/ButtonActiveGradient';
// @ts-ignore
import ContentLayout from './Layout/Content/ContentLayout.vue';
// @ts-ignore
import DefaultLayout from './Layout/Default/DefaultLayout.vue';
import {BranchAndGeoLocationService} from './Service/BranchAndGeoLocationService';
import {DateFormatter} from './Service/DateFormatter';
import {EtrackerService} from './Service/EtrackerService';
import {GoogleTagManagerService} from './Service/GoogleTagManagerService';
import {JobService} from './Service/JobService';
import {JsonLdService} from './Service/JsonLdService';
import {LanguageSwitch} from './Service/LanguageSwitch';
import {MailService} from './Service/MailService';
import {MenuService} from './Service/MenuService';
import {PhoneFormatter} from './Service/PhoneFormatter';
import {SavedJobService} from './Service/SavedJobService';
import {UcService} from './Service/UcService';

export default SpaApp.init({
    api: {
        baseUrl: ''
    },
    additionalConfiguration: (environment, config) => {
        // Use the app domain as base url
        config.api.baseUrl = 'https://' + environment.envVars.APP_DOMAIN;

        // Special SSR Settings
        if (config.vue.vueEnvironment === 'server') {
            // Allow the communication to another kubernetes node if required
            if (!isEmpty(environment.envVars.APP_KUBERNETES_BACKEND_URL)) {
                config.api.baseUrl = environment.envVars.APP_KUBERNETES_BACKEND_URL;
            }
            // Check if we are in dev mode -> docker compose url resolution
            else if (environment.type === 'development') {
                config.api.baseUrl = 'https://' + environment.envVars.COMPOSE_PROJECT_NAME + '-typo';
            }
        }
    },
    vue: {
        dynamicComponentResolver: function (type: string) {
            const parts = type.split('/');
            const isPlugin = parts.length === 4;
            if (isPlugin) {
                return import('./ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/'
                              + ucFirst(parts[2]) + '/' + ucFirst(parts[3]) + '.vue');
            } else {
                return import('./ContentElement/' + ucFirst(parts[0]) + '/' + ucFirst(parts[1]) + '/' + ucFirst(
                        parts[1]) + '.vue');
            }
        },
        appComponent: AppComponent as any,
        staticComponents: {
            appErrorComponent: ErrorComponent
        },
        layoutComponents: {
            '1': ContentLayout as any,
            default: DefaultLayout as any
        },
        globalConfiguration: (context, vue) => {
            vue.directive('button-active-gradient', ButtonActiveGradient);
        }
    },
    errorHandling: {
        routes: [
            {
                route: (err, context) => {
                    return LanguageSwitch.isMainLanguage() || context.translation.languageCode === 'en' ?
                            '/404' : '/' + context.translation.languageCode + '/404';
                },
                code: 404
            },
            {
                // @ts-ignore
                route: async (err, context) => {
                    if (err.type === 'network' && isArray(err.additionalPayload.response) && err.additionalPayload.response.indexOf('IS_JOB_404') !== -1) {
                        const redirect = LanguageSwitch.isMainLanguage() || context.translation.languageCode === 'en' ?
                                '/jobboerse' : '/' + context.translation.languageCode + '/';

                        if (context.isServer) {
                            await context.vueRenderContext.serverResponse.redirect(301, redirect);
                            return redirect;
                        }

                        return context.pageContext.router.replace(redirect);
                    }
                },
                code: 301
            }
        ]
    },
    router: {
        refreshCommonElements: ['anchors', 'pids', 'footerMenu', 'languageSwitcher', 'config']
    },
    staticMeta: {
        titleTemplate: '%s',
        meta: [
            {charset: 'utf-8'},
            {property: 'Content-Type', content: 'text/html'}
        ],
        script: [
            {
                vmid: 'usercentrics',
                id: 'usercentrics-cmp',
                src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
                'data-version': 'preview',
                'data-settings-id': 'ndkyD6wuo'
            },
            {
                vmid: 'facebookPixel',
                type: 'text/plain',
                'data-usercentrics': 'Facebook Pixel',
                charset: 'utf-8',
                innerHTML: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '139031443345749');
                fbq('track', 'PageView');
`,
                'data-type': 'application/javascript',
                'data-name': 'facebookPixel'
            },
            {
                vmid: 'salesviewer',
                type: 'text/plain',
                'data-usercentrics': 'SalesViewer',
                charset: 'utf-8',
                innerHTML: `
                (function(w,d,s,l,i){
                var g=w[l] ? '&s='+w[l]:'', f=d.getElementsByTagName(s)[0], j=d.createElement(s);
                j.async=true,j.src='https://svrdntfctn.com/stm.js?id='+i+g;
                j.referrerPolicy='no-referrer-when-downgrade';
                f.parentNode.insertBefore(j,f);
                })(window,document,'script','name','G7g0N4A6e7B6')
`,
                'data-type': 'application/javascript',
                'data-name': 'salesviewer'
            },
            {
                vmid: 'etracker',
                type: 'text/plain',
                'data-usercentrics': 'etracker',
                charset: 'utf-8',
                id: '_etLoader',
                defer: true,
                body: true,
                src: '//static.etracker.com/code/e.js',
                'data-block-cookies': 'true',
                'data-respect-dnt': 'true',
                'data-secure-code': 'Zl3ZKb'
            }
        ],
        __dangerouslyDisableSanitizersByTagID: {
            'gta': ['innerHTML'],
            'facebookPixel': ['innerHTML'],
            'salesviewer': ['innerHTML']
        }
    },
    events: {
        [FrameworkEventList.HOOK_VUE_CONFIG_FILTER]: function (e, context: AppContext) {
            if (context.vueEnv === 'client') {
                Vue.use(InfiniteLoading);
            }
            Vue.filter('phoneNumber', (v) => PhoneFormatter.format(v));
            Vue.config.errorHandler = errorHandler(appSignal, Vue);
        },
        [FrameworkEventList.HOOK_INIT]: function (e, context: AppContext) {
            // Initialize store keys
            context.store.set(AppStoreKeys.BREAKPOINT, BreakpointService.getCurrent() ?? {is: () => false});
            context.store.set(AppStoreKeys.BRANCH_SEARCH_IS_SELECT, false);

            // Initialize services
            MenuService.initialize(context);
            DateFormatter.initialize(context);
            MailService.initialize(context);
            LanguageSwitch.initialize(context);
            SavedJobService.initialize(context);
            JsonLdService.initialize(context);

            // Wait until we have the branch and location loaded
            return BranchAndGeoLocationService
                    .initialize(context)
                    .then(() => JobService.initialize(context));
        },
        [FrameworkEventList.HOOK_INIT_BROWSER]: function (e, context: AppContext) {
            EtrackerService.initialize();
            UcService.init(context);

            // Register global events to the event system
            registerEventScrollThrottled();
            registerEventBreakPointChange();
            registerEventResizeThrottled();

            // Configure scroll behaviour
            configureScrollToTopOf({breakOnManualScroll: true});
        },
        [HelferleinEventList.EVENT_SCROLL_THROTTLED]: function (e, context: AppContext) {
            context.store.set(AppStoreKeys.SCROLL_POS, getScrollPos());
        },
        [HelferleinEventList.EVENT_BREAKPOINT_CHANGE]: function (e, context: AppContext) {
            context.store.set(AppStoreKeys.BREAKPOINT, BreakpointService.getCurrent());
        },
        [FrameworkEventList.EVENT_ROUTE_BEFORE_NAVIGATION]: function (e, context: AppContext) {
            scrollToTopOf();

            // Show loader
            context.store.set(AppStoreKeys.APP_LOADER_SHOW, true);
        },
        [FrameworkEventList.EVENT_ROUTE_AFTER_NAVIGATION]: function (e, context: AppContext) {
            // Hide the loader
            context.store.set(AppStoreKeys.APP_LOADER_SHOW, false);

            // Trigger the tracking event
            EtrackerService.trackPageChange();
            EtrackerService.initializeOptOutSnippets();

            // Track the navigation
            GoogleTagManagerService.trackPageChange();

            // Update date and time format
            try {
                configureFormatDateAndTime(context.translation.translate('formatDateAndTime') as string);
            } catch (e) {
                console.error('Failed to load date and time translations');
            }

            // Reset page header overrides
            forEach([
                AppStoreKeys.PAGE_HEADLINE_OVERRIDE,
                AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE,
                AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE,
                AppStoreKeys.MAGAZINE_HEADLINE_OVERRIDE,
                AppStoreKeys.CARE_HEADLINE_OVERRIDE,
                AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE,
                AppStoreKeys.PAGE_TEASER_VIDEO_POSTER_OVERRIDE
            ], key => {
                context.store.set(key, undefined);
            });
        },
        [FrameworkEventList.HOOK_ROUTE_QUERY_FILTER]: function (e) {
            // Make sure we send the correct content context to the backend queries
            e.args.query.homePid = MenuService.getHomePid();
        }
    }
});

