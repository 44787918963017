
























import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import ImageTag from '../../../../Misc/Image/ImageTag/ImageTag.vue';
import HomeLinkWrap from '../../../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';

export default {
    name: 'Logo',
    components: {HomeLinkWrap, ImageTag},
    props: {
        context: null as PageContext,
        rootLine: null as Array<RootLineElementInterface>
    },
    data()
    {
        return {
            logoImage: {
                url: '/assets/persona-logo.svg',
                image: {
                    alt: this.$t('navigation.logo.alt')
                }
            }
        };
    }
};
