var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "imageSlider",
      class: { "imageSlider--hasDesc": _vm.hasDesc },
    },
    [
      _vm.isSlider
        ? _c(
            "div",
            { staticClass: "imageSlider__slider" },
            [
              _c(
                "hooper",
                {
                  attrs: {
                    wheelControl: false,
                    autoPlay: _vm.isAutoplay,
                    playSpeed: _vm.slideDuration,
                  },
                },
                [
                  _vm._l(_vm.images, function (image, k) {
                    var _obj, _obj$1
                    return _c(
                      "slide",
                      {
                        key: image.id,
                        staticClass: "imageSlider__slide",
                        class:
                          ((_obj = {}),
                          (_obj["imageSlider__slide--" + k] = true),
                          _obj),
                      },
                      [
                        _c("image-tag", {
                          class:
                            ((_obj$1 = {}),
                            (_obj$1["imageSlider__image--" + k] = true),
                            _obj$1),
                          attrs: {
                            image: image,
                            definition: _vm.definition,
                            crop: _vm.crop,
                            "lazy-loading": _vm.lazyLoading,
                            "bp-definitions": _vm.bpDefinitions,
                          },
                        }),
                        _vm._v(" "),
                        _vm._t("slide" + k),
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c("hooper-pagination", {
                    attrs: { slot: "hooper-addons" },
                    slot: "hooper-addons",
                  }),
                ],
                2
              ),
            ],
            1
          )
        : !_vm.hasMedia
        ? _c(
            "div",
            {
              staticClass:
                "imageSlider__slide imageSlider__slide--0 imageSlider__single",
            },
            [
              _c("image-tag", {
                staticClass: "imageSlider__image--0",
                attrs: {
                  image: _vm.images[0],
                  definition: _vm.definition,
                  crop: _vm.crop,
                  "bp-definitions": _vm.bpDefinitions,
                  "lazy-loading": _vm.lazyLoading,
                },
              }),
              _vm._v(" "),
              _vm._t("slide0"),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }