var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobMailTeaser" },
    [
      _c("span", { staticClass: "jobMailTeaser__subtitle" }, [
        _vm._v(_vm._s(_vm.internalSubtitle)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "jobMailTeaser__headline" }, [
        _vm._v(_vm._s(_vm.internalHeadline)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "jobMailTeaser__text" }, [
        _vm._v(_vm._s(_vm.internalText)),
      ]),
      _vm._v(" "),
      _vm.hasInput
        ? _c("input-field", {
            staticClass: "jobMailTeaser__input",
            attrs: {
              placeholder: "E-Mail",
              type: "email",
              "email-validation-label": _vm.$t("job.alert.modal.error.mail"),
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Btn",
        {
          staticClass: "jobMailTeaser__btn",
          attrs: { identifier: _vm.btnIdentifier },
          nativeOn: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [_vm._v(_vm._s(_vm.internalCta))]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [_c("job-alert-modal", { attrs: { mail: _vm.email } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }