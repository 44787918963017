











































































































































import {isBrowser} from '@labor-digital/helferlein/lib/Environment/isBrowser';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import Modal from '../Modal/Modal.vue';

export default {
    name: 'ShareModal',
    components: {Modal},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            required: true
        },
        link: {
            type: String
        },
        type: {
            type: String,
            default: 'job'
        }
    },
    computed: {
        isJob(): boolean
        {
            return this.type === 'job';
        },
        modalOpen: {
            get()
            {
                return this.value;
            },
            set(val)
            {
                this.$emit('input', val);
            }
        },
        message: function () {
            return encodeURIComponent(this.text);
        },
        url: function () {
            if (!isEmpty(this.link)) {
                return this.link;
            }
            if (!isBrowser()) {
                return '';
            }
            return encodeURIComponent(window.location.href + '');
        }
    }
};
