


























import {isEmpty} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'Button',
    components: {Btn, LinkWrap},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            isGhost: this.context.data.get('isGhost', false),
            type: this.context.data.get('type', 'link'),
            label: this.context.data.get('label'),
            hasIcon: this.context.data.get('hasIcon'),
            icon: this.context.data.get('icon'),
            iconPosition: this.context.data.get('iconPosition', ''),
            href: this.context.data.get('href', '#'),
            isHover: false
        };
    },
    computed: {
        hasLabel(): Boolean
        {
            return !isEmpty(this.label);
        }
    }
};
