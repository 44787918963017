var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumb" }, [
    _c(
      "div",
      { staticClass: "breadcrumb__inner" },
      [
        !_vm.isLandingpage
          ? _c(
              "span",
              { staticClass: "breadcrumb__link" },
              [
                _c("home-link-wrap", { ref: "labelElement-broot" }, [
                  _vm._v(_vm._s(_vm.$t("breadcrumb.root.home"))),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.rootLine, function (root) {
          return _c(
            "span",
            { staticClass: "breadcrumb__link" },
            [
              _c(
                "link-wrap",
                {
                  ref: "labelElement-b" + root.id,
                  refInFor: true,
                  attrs: { link: root.slug },
                },
                [_vm._v(_vm._s(root.title))]
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }