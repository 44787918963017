





































import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {escapeRegex} from '@labor-digital/helferlein/lib/Strings/escapeRegex';
import {isString} from '@labor-digital/helferlein/lib/Types/isString';
import Url from 'url-parse';

export default {
    name: 'LinkWrap',
    props: {
        /**
         * Additional html properties to add to the link
         */
        linkProps: {
            type: Object,
            default: () => {
                return {};
            }
        } as PlainObject,

        /**
         * Defines the forced type of the button to render
         * Values are:
         *  - "auto" automatically select the type based on the given link prop
         *  - "external" always open the link in a new window
         *  - "phone" always handle the link as a phone number
         *  - "js" emits a vue event "click" when the button was clicked, but does nothing else
         *  - "mail" executes a "mailto:" to the given "link"
         */
        type: {
            type: String,
            default: 'auto',
            validator(v)
            {
                return (['auto', 'external', 'phone', 'js', 'mail', 'anchor']).indexOf(v) !== -1;
            }
        },

        /**
         * Defines which link tag should be generated
         */
        tag: {
            type: String,
            default: 'a'
        },

        /**
         * If set to true the button will not do anything
         */
        disabled: {
            type: Boolean,
            default: false
        },

        /**
         * The link to open when a user clicks on this link
         */
        link: {
            type: String,
            default: ''
        },

        /**
         * Allows you to provide a map of a phone number prefix "key" that should be converted into an
         * international prefix "value"
         */
        phoneIntlMap: {
            '0': '+49'
        },

        /**
         * Forces the link to always handle redirect the user to a new tab
         * @deprecated use "type": "external" instead!
         */
        alwaysExternal: {
            type: Boolean,
            default: false
        },

        /**
         * Render the link as disabled element
         * @deprecated use "disabled" instead
         */
        isDisabled: {
            type: Boolean,
            default: false
        },

        /**
         * If this is set to true the component will emit an event of type "click" (NOT NATIVE!)
         * when the link was clicked instead of following the link. The default action is prevented!
         * @deprecated use "type": "js" instead
         */
        emitOnClick: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Tires to find the correct type of the link if "auto" is selected as type
         */
        realType(): string
        {
            if (this.type !== 'auto') {
                return this.type;
            }

            // JS
            if (this.emitOnClick) {
                return 'js';
            }

            // NOOP
            if (!isString(this.cleanLink) || this.cleanLink === '' || this.isDisabled || this.disabled) {
                return 'noop';
            }

            // PHONE
            if (this.cleanLink.match(/[^0-9\s+\-/():]/) === null && this.cleanLink.length >= 8) {
                return 'phone';
            }

            // E-MAIL
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(this.cleanLink)) {
                return 'mail';
            }


            if (this.cleanLink.startsWith('#')) {
                return 'anchor';
            }

            if (this.cleanLink.startsWith('/')) {
                return 'internal';
            }

            // EXTERNAL
            const url = new Url(this.cleanLink);
            const baseUrl = new Url(this.$root.appContext.pageContext.baseUrl);
            if (url.host !== baseUrl.host || this.cleanLink.indexOf('fileadmin') !== -1 || this.alwaysExternal) {
                return 'external';
            }

            // Fall back to router link
            return 'internal';
        },

        /**
         * Processes the href attribute based on the type of the given link
         */
        href(): string
        {
            switch (this.realType) {
                case 'anchor':
                    return this.cleanLink;
                case 'mail':
                    return '';
                case 'phone':
                    let number = this.cleanLink;
                    forEach(this.phoneIntlMap, (v, k) => {
                        if (number.indexOf(k + '') === 0) {
                            number = number.replace(new RegExp('^' + k + ''), v);
                            return false;
                        }
                    });
                    return 'tel:' + number;
                case 'internal':
                    const url = new Url(this.cleanLink);
                    const pattern = new RegExp('^' + escapeRegex(url.protocol + '//' + url.host));
                    return this.cleanLink.replace(pattern, '');
                case 'external':
                    if (this.cleanLink.match(/^https?:/) === null) {
                        return 'http://' + this.cleanLink;
                    }
                    return this.cleanLink;
                default:
                    return this.cleanLink;
            }
        },

        /**
         * The list of generated css classes for this link
         */
        classes(): PlainObject
        {
            return {
                ['linkWrap--' + this.realType]: true
            };
        },

        /**
         * The list of required event handlers on the button component
         */
        listeners(): PlainObject
        {
            switch (this.realType) {
                case 'noop':
                    return {
                        'click': this.onNoopClick
                    };
                case 'js':
                    return {
                        'click': this.onClick
                    };
                case 'mail':
                    return {
                        'click': this.onMailClick
                    };
                case 'anchor':
                    return {
                        'click': this.onAnchorClick
                    };
                default:
                    return {};
            }
        },

        /**
         * The list of required html attributes to add to the button node
         */
        attr(): PlainObject
        {
            let attr = this.linkProps;
            if (this.realType === 'external') {
                attr.target = '_blank';
                attr.rel = 'noopener,noreferrer';
            }
            return attr;
        },

        cleanLink()
        {
            if (isEmpty(this.link)) {
                return this.link;
            }

            const link = this.link.split(' - - ');
            return link[0];
        }
    },
    methods: {
        onClick(e: MouseEvent)
        {
            e.preventDefault();
            this.$emit('click', e);
        },
        onNoopClick(e: MouseEvent)
        {
            e.preventDefault();
        },
        onAnchorClick(e: MouseEvent)
        {
            e.preventDefault();
            const anchorPoint = document.getElementById(this.cleanLink.slice(1));
            if (anchorPoint !== null) {
                window.scrollTo({
                    top: anchorPoint.offsetTop,
                    behavior: 'smooth'
                });
            }
        },
        onMailClick(e: MouseEvent)
        {
            e.preventDefault();
            window.location.href = 'mailto:' + this.link;
        }
    }
};
