/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.10.06 at 17:35
 */

import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';

export class LanguageSwitch
{

    /**
     * The app context we use to access the store
     */
    protected static _context: AppContext;

    /**
     * Initializes the service by injecting the context
     * @param context
     */
    public static initialize(context: AppContext)
    {
        this._context = context;
    }

    /**
     * Returns true as long as the main language is selected
     * This allows you to switch the layout when the language changed
     */
    public static isMainLanguage(): boolean
    {
        return this._context.translation.languageCode === 'de';
    }
}
