var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isEmpty
    ? _c(
        "ul",
        { staticClass: "linkCloud" },
        _vm._l(_vm.links, function (link) {
          return _c(
            "li",
            { staticClass: "linkCloud__links" },
            [
              _c("link-wrap", { attrs: { link: link.slug } }, [
                _vm._v(_vm._s(link.title)),
              ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }