































































import {getSize} from '@labor-digital/helferlein/lib/Dom/getSize';
import {isObject} from '@labor-digital/helferlein/lib/Types/isObject';
import {getScrollPos} from '@labor-digital/helferlein/lib/Ui/getScrollPos';
import {stopBodyScrolling} from '@labor-digital/helferlein/lib/Ui/stopBodyScrolling';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {FrameworkEventList} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/FrameworkEventList';
import {AppStoreKeys} from '../../../AppStoreKeys';
import Button from '../../../ContentElement/Persona/Button/Button.vue';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';
import {MenuService} from '../../../Service/MenuService';
import {ScrollThrottle} from '../../../Service/ScrollThrottle';
import Btn from '../../Misc/Btn/Btn.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import Search from '../Search/Search.vue';

/**
 * The main sidebar container with options to show a left and a right sidebar
 * The right sidebar is the "mobile-menu", the left sidebar be configured freely.
 *
 * To set the component for the left sidebar use the "appSidebarComponentLeft" variable in the store
 * To open either the left or the right sidebar set the "appSidebarDirection" variable to "left" or to "right"
 * To close the sidebars set the "appSidebarDirection" variable to NULL
 *
 * The sidebar width is calculated based on the actual content so make sure your child-components
 * respect a max-width: 100vw on mobile devices
 */
export default {
    name: 'AppSidebarContainer',
    components: {
        Search,
        FixedActionButtons: () => import('../FixedButtons/Actions/FixedActionButtons.vue'),
        FixedActionButtonSlideInMenu: () => import( '../FixedButtons/Actions/SlideInMenu/FixedActionButtonSlideInMenu.vue'),
        FixedToTopButton: () => import( '../FixedButtons/ToTop/FixedToTopButton.vue'),
        Btn,
        Button,
        FixedHeader: () => import('../FixedHeader/FixedHeader.vue'),
        Icon,
        MainMenuMobile: () => import('../MainMenu/Mobile/MainMenuMobile.vue')
    },
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            scrollPos: 0,
            scrollDistance: 0,

            isSidebarOpen: false,
            isAnimated: false,
            railPosition: 0,
            leftComponent: null,
            isOverlayShown: false,
            isScrollingBlocked: false,

            leftSidebarWidth: 9999,
            rightSidebarWidth: 9999,

            showAsScrolled: false,
            blockScrollDirectionChange: false,
            bsdcTimeout: 0,
            loadComponent: false,

            throttleTimer: false,
            throttleDelay: 100,
            scrollThrottle: null
        };
    },
    computed: {
        isMobile(): boolean
        {
            return this.context.store.get(AppStoreKeys.BREAKPOINT).is('<=', 'md');
        },
        /**
         * Calculates the correct direction based on the AppStoreKeys.SIDEBAR_DIRECTION store key
         */
        direction(): 'left' | 'right' | null
        {
            const dir = this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION, null);
            return ['left', 'right'].indexOf(dir) === -1 ? null : dir;
        },
        /**
         * True if the main menu should be fixed while a sidebar is opened
         */
        isMenuFixed(): boolean
        {
            return this.context.store.get(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, false);
        },
        /**
         * Are we on a Landingpage?
         */
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages();
        },
        /**
         * True if the search overlay should be shown
         */
        isSearchVisible(): boolean
        {
            return this.context.store.get(AppStoreKeys.SEARCH_OVERLAY_IS_VISIBLE, false);
        },
        /**
         * Calculates the class list for the outer container
         */
        classes()
        {
            return {
                'appSidebarContainer--open': this.isSidebarOpen,
                'appSidebarContainer--transition': this.isAnimated,
                'appSidebarContainer--menuFixed': this.isMenuFixed,
                'appSidebarContainer--scrollBlocked': this.isScrollingBlocked,
                'appSidebarContainer--scrolled': this.showAsScrolled ||
                                                 this.isOverlayShown && !this.isMenuFixed
            };
        },
        /**
         * Calculates the css style attributes for the left sidebar
         */
        styleLeftSidebar()
        {
            return {
                left: this.railPosition <= 0 ?
                        -this.leftSidebarWidth + 'px' : 0 + 'px'
            };
        },
        /**
         * Calculates the css style attributes for the right sidebar
         */
        styleRightSidebar()
        {
            return {
                right: this.railPosition >= 0 ?
                        -this.rightSidebarWidth + 'px' : 0 + 'px'
            };
        },
        /**
         * Calculates the css style attributes to move the rail left and right
         */
        styleRailContainer()
        {
            return {
                marginLeft: this.railPosition + 'px',
                paddingTop: this.isLandingpage ? 0 : null
            };
        }
    },
    methods: {
        /**
         * Opens the sidebar which was registered using the AppStoreKeys.SIDEBAR_DIRECTION key
         * to determine the side from which the menu should open.
         */
        openSidebar()
        {
            // Ignore if already open
            if (this.isSidebarOpen) {
                return;
            }

            // Mark element as open
            this.isSidebarOpen = true;

            // Special handling for left sidebar -> place the component first
            if (this.direction === 'left') {
                this.leftComponent =
                        this.context.store.get(AppStoreKeys.SIDEBAR_COMPONENT_LEFT, null);

                // Skip if the left component can't be validated
                if (!isObject(this.leftComponent)) {
                    console.error('Failed to validate the left sidebar component! Closing the menu again!');
                    this.closeSidebars();
                    return;
                }
            }

            // Wait for the next tick
            this.$nextTick(() => {
                // Calculate the width
                if (this.direction === 'left') {
                    this.leftSidebarWidth = getSize(this.$refs.left).width;
                } else {
                    this.rightSidebarWidth = getSize(this.$refs.right).width;
                }
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        // Mark the element as animated
                        this.isAnimated = true;
                        // Calculate the new rail position
                        if (this.direction === 'left') {
                            this.railPosition = this.leftSidebarWidth;
                        } else if (this.direction === 'right') {
                            this.railPosition = -this.rightSidebarWidth;
                        }

                        // Open overlay
                        this.isOverlayShown = true;
                        // Stop the body scrolling
                        this.isScrollingBlocked = true;
                    });
                });
            });
        },

        /**
         * Closes the sidebars and unlocks the scrolling
         */
        closeSidebars()
        {
            // Ignore if already closed
            if (!this.isSidebarOpen) {
                return;
            }

            // Make sure the menu stays open
            if (this.isMenuFixed) {
                this.showAsScrolled = false;
            }

            // Move the content back to the center
            this.railPosition = 0;
            this.isOverlayShown = false;

            setTimeout(() => {
                // Wait until the animation has finished...
                // Reset all variables
                this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, false);
                this.context.store.set(AppStoreKeys.SIDEBAR_DIRECTION, null);
                this.context.store.set(AppStoreKeys.SIDEBAR_COMPONENT_LEFT, null);
                this.isAnimated = false;
                this.isScrollingBlocked = false;
                this.leftSidebarWidth = 9999;
                this.rightSidebarWidth = 9999;
                this.$nextTick(() => {
                    this.showAsScrolled = false;
                    this.isSidebarOpen = false;
                    this.context.store.set(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, false);
                });
            }, 300);
        },

        /**
         * Only shows the overlay with no sidebars
         */
        showOverlay()
        {
            // Ignore if the menu is open
            if (this.isSidebarOpen || this.isOverlayShown) {
                return;
            }

            // Open the overlay
            this.isOverlayShown = true;
            this.isScrollingBlocked = true;
        },

        /**
         * Hides the overlay if it was opened without opening a sidebar
         */
        hideOverlay()
        {
            // Ignore if the menu is open
            if (this.isSidebarOpen || !this.isOverlayShown) {
                return;
            }

            // Close the overlay
            this.isOverlayShown = false;
            this.isScrollingBlocked = false;
            this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, false);
            this.context.store.set(AppStoreKeys.SIDEBAR_MAIN_MENU_FIXED, false);
        },

        /**
         * Handles the click on the overlay to close the sidebar/overlay correctly
         */
        onOverlayClick()
        {
            if (this.isSidebarOpen) {
                this.closeSidebars();
            } else {
                this.hideOverlay();
            }
        },

        /**
         * Called when the page was scrolled. Is used to hide/show the fixed
         * header if the user scrolled up for a short while.
         */
        onScroll()
        {
            // Ignore if we are already in open state
            if (this.isSidebarOpen) {
                return;
            }

            this.loadComponent = true;

            // Detect the direction we are moving in
            const oldScrollPos = this.scrollPos;
            this.scrollPos = getScrollPos(window);
            const oldDirection = this.scrollDistance < 0 ? 'up' : 'down';
            const direction = oldScrollPos > this.scrollPos ? 'up' : 'down';

            // Calculate the distance we have moved
            if (direction !== oldDirection) {
                // Check if the direction change is blocked
                if (this.blockScrollDirectionChange) {
                    return;
                }
                this.scrollDistance = 0;
            }
            this.blockScrollDirectionChange = true;
            this.proxy.clearTimeout(this.bsdcTimeout);
            this.bsdcTimeout = this.proxy.setTimeout(() => this.blockScrollDirectionChange = false, 150);
            const distance = Math.abs(oldScrollPos - this.scrollPos);
            if (direction === 'up') {
                this.scrollDistance -= distance;
            } else {
                this.scrollDistance += distance;
            }

            // Skip if we have not scrolled enough
            if (Math.abs(this.scrollDistance) < (
                    direction === 'up' ? 50 : 100
            )) {
                return;
            }
            this.showAsScrolled = direction !== 'up';
        },

        /**
         * Event handler that is called when the viewport changed,
         * or the user navigated, meaning we should close all open elements
         */
        onResetRequired()
        {
            this.closeSidebars();
            this.hideOverlay();
        },
        throttleScroll(callback)
        {
            if (!this.throttleTimer) {
                this.throttleTimer = true;
                requestAnimationFrame(() => {
                    callback();
                    this.throttleTimer = false;
                });
            }
        }
    },
    mounted(): void
    {
        // Find the current scroll position
        this.scrollPos = getScrollPos(window);

        this.scrollThrottle = new ScrollThrottle(this.onScroll);
        this.scrollThrottle.start();

        this.proxy.bind(
                this.context.eventEmitter,
                FrameworkEventList.EVENT_ROUTE_BEFORE_NAVIGATION,
                this.onResetRequired);

        // Watch the sidebar properties
        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_DIRECTION), (n) => {
            if (n !== null) {
                this.openSidebar();
            } else {
                this.closeSidebars();
            }
        });
        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_COMPONENT_LEFT), (n) => {
            // Make sure the direction is set to left when the component is given
            if (n !== null) {
                this.context.store.set(AppStoreKeys.SIDEBAR_DIRECTION, 'left');
            } else {
                this.closeSidebars();
            }
        });

        // Watch the overlay store property
        this.$watch(() => this.context.store.get(AppStoreKeys.SIDEBAR_SHOW_OVERLAY), (n) => {
            if (n) {
                this.showOverlay();
            } else {
                this.hideOverlay();
            }
        });
    },
    beforeDestroy()
    {
        this.scrollThrottle.stop();
    },
    watch: {

        /**
         * Enables/disables the body scrolling on demand
         * @param n
         * @param o
         */
        isScrollingBlocked(n, o)
        {
            // Ignore if this is a pseudo change
            if (n === o) {
                return;
            }
            stopBodyScrolling(n);
        }
    }
};
