








































































import {isEmpty, validateEmail} from '@labor-digital/helferlein';
import {FbPixelService} from '../../../Service/FbPixelService';
import Checkbox from '../../Form/Checkbox/Checkbox.vue';
import InputField from '../../Form/InputField/InputField.vue';
import Btn from '../../Misc/Btn/Btn.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import Spinner from '../../Misc/Spinner/Spinner.vue';
import JobAlertLocation from './JobAlertLocation/JobAlertLocation.vue';
import JobAlertOccupation from './JobAlertOccupation/JobAlertOccupation.vue';

export default {
    name: 'JobAlertModal',
    components: {LinkWrap, JobAlertLocation, JobAlertOccupation, InputField, Btn, Spinner, Icon, Checkbox},
    props: {
        mail: String
    },
    data()
    {
        return {
            name: '',
            surname: '',
            email: this.mail,
            location: '',
            occupation: '',
            confirmGdpr: false,
            confirmGdprOptions: [
                {
                    label: 'privacy policy',
                    value: 'gdpr',
                    checked: false,
                    confirmGdpr: true
                }
            ],
            isSending: false,
            nameError: '',
            surnameError: '',
            mailError: '',
            occupationError: '',
            locationError: '',
            confirmGdprError: '',
            formSent: false
        };
    },
    computed: {
        // @todo make this more intuitive <- Single checkbox component
        gdprModelBridge: {
            set(v)
            {
                this.confirmGdpr = v.length > 0;
            }, get()
            {
                return [];
            }
        },
        privacyPolicyPage()
        {
            const pageContext = this.$root.appContext.pageContext;
            return pageContext.linkRepository.get('privacyPolicy');
        }
    },
    methods: {
        send()
        {
            this.isSending = true;
            const location = this.location ?? null;

            this.nameError = !isEmpty(this.name) && this.name.length < 3 ? this.$t('job.alert.modal.error.short') : '';
            this.surnameError = !isEmpty(this.surname) && this.surname.length < 3 ? this.$t('job.alert.modal.error.short') : '';
            this.mailError = isEmpty(this.email) && !validateEmail(this.email) ? this.$t('job.alert.modal.error.mail') : '';
            this.occupationError = isEmpty(this.occupation) ? this.$t('job.alert.modal.error.occupation') : '';
            this.locationError = isEmpty(location) ? this.$t('job.alert.modal.error.empty') : '';
            this.confirmGdprError = !this.confirmGdpr ? this.$t('requestEmployees.form.confirmGdpr.error') : null;

            if (!isEmpty(this.nameError) || !isEmpty(this.surnameError) || !validateEmail(this.email) || !isEmpty(this.occupationError) ||
                !isEmpty(this.locationError) || !this.confirmGdpr) {
                this.isSending = false;
                return;
            }

            this.$root.appContext.axios.post('jobAlert/register', {
                    name: this.name,
                    surname: this.surname,
                    email: this.email,
                    location: location.address,
                    jobOccupationalAreaName: this.occupation,
                    confirmGdpr: this.confirmGdpr
                })
                .catch(err => {
                    console.error(err);
                })
                .then(() => {
                    this.isSending = false;
                    this.formSent = true;

                    FbPixelService.subscribe();
                });
        }
    }
};
