


























import Spinner from './Spinner.vue';

export default {
    name: 'InfinityLoadingSpinner',
    components: {Spinner}
};
