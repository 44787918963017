































import {PlainObject} from '@labor-digital/helferlein';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import ImageTag from '../../../../Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'FooterMenu_Networks',
    components: {ImageTag, LinkWrap},
    props: {
        context: {
            required: true
        } as PageContext
    },
    computed: {
        networks(): Array<PlainObject>
        {
            return (this.context as PageContext).commonElements.networks;
        }
    }
};
