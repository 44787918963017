/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.08.09 at 12:38
 */

export enum AppEventKeys
{
    CLOSE_MAIN_MENU_DROPDOWN = 'app__closeMainMenuDropdown',
    CLOSE_SEARCH_DROPDOWN = 'app__closeSearchDropdown',
    OPEN_LOGIN_MENU = 'app_openLogin_menu'
}
