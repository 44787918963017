








































































import {isBrowser} from '@labor-digital/helferlein/lib/Environment/isBrowser';
import {formatDateAndTime} from '@labor-digital/helferlein/lib/FormatAndConvert/formatDateAndTime';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {getPath} from '@labor-digital/helferlein/lib/Lists/Paths/getPath';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {BreakpointService} from '@labor-digital/helferlein/lib/Ui/BreakpointService/BreakpointService';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../Service/MenuService';
import CurrentBranch from '../../Branch/CurrentBranch/CurrentBranch.vue';
import LinkCloud from '../../Misc/Link/LinkCloud/LinkCloud.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import FooterMenu_Networks from './Components/Networks/Networks.vue';

export default {
    name: 'FooterMenu',
    components: {FooterMenu_Networks, CurrentBranch, LinkCloud, LinkWrap},
    mixins: [LanguageSwitchMixin],
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            titleHeight: null
        };
    },
    computed: {
        isLandingpage(): Boolean
        {
            return MenuService.isHomepageForLandingpages();
        },
        year()
        {
            return formatDateAndTime('Y');
        },
        footer()
        {
            return getPath(this.context.commonElements, 'footerMenu', {});
        },
        footerLayout(): string
        {
            return !isEmpty(this.footer.footerLayout) ? this.footer.footerLayout : 'default';
        },
        metaFooter()
        {
            return getPath(this.context.commonElements, 'metaFooterMenu', {});
        },
        titleStyle(): PlainObject
        {
            return {
                height: this.titleHeight
            };
        },
        classes()
        {
            return {
                'footerMenu__col--wide': this.footerLayout === 'default',
                'footerMenu__col--full': this.footerLayout === 'business'
            };
        }
    },
    methods: {
        calculateTitleHeight(): void
        {

            // Handle Ssr
            if (!isBrowser()) {
                this.titleHeight = null;
                return;
            }

            // No calculation on small breakpoints
            if (BreakpointService.bpIs('<=', 'xs')) {
                this.titleHeight = null;
                return;
            }
            // Get elements
            const els = document.querySelectorAll('[data-ref=\'footer-menu-column-title\']');
            if (isEmpty(els)) {
                return;
            }

            // Restore and calculate the new height
            let maxHeight = -1;
            forEach(els, (ref: HTMLElement) => {
                let heightBackup = ref.style.height;
                ref.style.height = null;
                maxHeight = Math.max(maxHeight, ref.offsetHeight);
                ref.style.height = heightBackup;
            });

            // Done
            this.titleHeight = maxHeight + 'px';
        },
        limitLinkLabel(v): string
        {
            return v.match(/.{1,25}/g).join('&shy;');
        }
    },
    mounted(): void
    {
        this.calculateTitleHeight();
        this.$watch(() => this.context.store.get(AppStoreKeys.BREAKPOINT),
            () => this.calculateTitleHeight());
        this.$watch(() => this.$route.path,
            () => this.calculateTitleHeight());
    }

};
