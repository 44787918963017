

























import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import LayoutHeader from '../../Component/Layout/LayoutHeader/LayoutHeader.vue';

export default {
    name: 'ContentLayout',
    components: {LayoutHeader},
    props: {
        context: null as PageContext
    }
};
