

























export default {
    name: 'Spinner',
    props: {
        show: {
            type: Boolean,
            default: true
        },
        delay: {
            type: Number,
            default: 50
        }
    }
};
