/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.05.22 at 13:13
 */

import {isEmpty} from '@labor-digital/helferlein';
import {chunks} from '@labor-digital/helferlein/lib/Lists/chunks';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';

// The regex to match german phone prefixes
// Source: https://gist.github.com/cerlestes/52b377a84c1c51c83545b29d0aa41193
const prefixMatcher = /^(0(?:30|40|69|89|20[12389]|21[24]|228|234|335|340|3[3-9][15]|906|[2-9][1-9]1|3(?:3(?:[0237][127]|0[3-6]|2[89]|3[458]|[46][1246]|7[589]|8[1256]|9[145])|4(?:[2347][135]|[34]7|[69][146]|48|62|76|93)|5(?:[04679][14]|[238][135]|[2469]2|[4789]6|[278]8|63|73|37|29)|6(?:[024689][13]|[03789]5|06|2[2489]|3[1246]|44|47|7[1279]|82|86)|7(?:[236][135]|2[2467]|37|4[145]|62|64|7[1-4])| 8(?:[234678]1|3[468]|4[347]|6[035-9]|7[467]|83|86)|9(?:[236][135]|[04689]4|[04789]1|[03468]7|02|09|28|4[369]|6[2689]|73|76|96|98))|3[3-9][0-9]{3}|15[1279][0-9]|1[67][0-9]|13[078]|180[235]|18[01]|19[0-9]{3}|[789]00|[2-9][0-9]{3}))(.*?)$/;

/**
 * Helper service to format phone numbers according to the layout
 */
export class PhoneFormatter
{

    /**
     * Formats german phone numbers to match the design
     * NOTE: Does ignore international phone number that don't start with +49
     * NOTE 2: Formats international phone number to match the international standard
     * @param number
     */
    public static format(number: string | number): string
    {
        if (isEmpty(number)) {
            return '';
        }

        number = (number + '').replace(/[^0-9+]/g, '');
        let useFallbackFormat = false;

        // Handle international phone numbers
        if (number.charAt(0) === '+') {
            if (number.indexOf('+49') === 0) {
                number = '0' + number.substr(3);
            } else {
                useFallbackFormat = true;
            }
        }

        // Format the number
        if (!useFallbackFormat) {
            // Format german phone number
            const matches = number.match(prefixMatcher);
            return matches[1] + ' / ' + matches[2];
        } else {
            const strChunks = chunks(number.split(''), 3) as Array<Array<string>>;
            let result = '';
            forEach(strChunks, chunk => {
                result += ' ' + chunk.join('');
            });
            return result;
        }
    }
}
