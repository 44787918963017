var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jobMetaAction jobMetaAction--saveJob" }, [
    _c(
      "button",
      _vm._b(
        {
          staticClass: "jobMetaAction__button",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        "button",
        _vm.iconHoverContainerProps(),
        false
      ),
      [
        _c("icon", {
          staticClass: "jobMetaAction__icon",
          attrs: {
            icon: "heart",
            filled: _vm.isSaved || this.iconHoverStates["default"],
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "jobMetaAction__label" }, [
          _vm._v(_vm._s(_vm.$t(_vm.label))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }