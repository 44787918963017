var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
            {
              key: "close-icon",
              fn: function () {
                return [_c("icon", { attrs: { icon: "close" } })]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.emittedValue,
          callback: function ($$v) {
            _vm.emittedValue = $$v
          },
          expression: "emittedValue",
        },
      },
      "base-modal",
      Object.assign({}, _vm.$props, _vm.$attrs),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }