/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.04.17 at 17:38
 */

/**
 * The list of keys that are available in the store for this application.
 */
export enum AppStoreKeys
{
    /**
     * True if the branch search map should be rendered in select mode
     */
    UC_STORE = 'ucStore',

    /**
     * Contains the current breakpoint based on the viewport width
     */
    BREAKPOINT = 'breakpoint',

    /**
     * The current scroll position from the top of the page
     */
    SCROLL_POS = 'scrollPos',

    /**
     * Contains the current branch
     */
    CURRENT_BRANCH = 'currentBranch',

    /**
     * Contains the currently selected geo location
     */
    CURRENT_LOCATION = 'currentLocation',

    /**
     * Global trigger to show the overlay on the main content container
     * Used when a modal is open or if the main menu dropdown is open
     */
    SIDEBAR_SHOW_OVERLAY = 'appSidebarShowOverlay',

    /**
     * Contains either "left", "right" or NULL to open or close the sidebar container
     */
    SIDEBAR_DIRECTION = 'appSidebarDirection',

    /**
     * The component that has to be set for the "left" sidebar, before it can be opened
     * It has to be a valid vue component
     */
    SIDEBAR_COMPONENT_LEFT = 'appSidebarComponentLeft',

    /**
     * By Default: FALSE, set this to true if you want to force the main
     * menu to be visible if a sidebar is opened.
     * FALSE (default) means we actively hide the main menu if we open the sidebar!
     */
    SIDEBAR_MAIN_MENU_FIXED = 'appSidebarMainMenuFixed',

    /**
     * By Default: FALSE, set this to true if you want to force the search overlay
     * to be visible.
     */
    SEARCH_OVERLAY_IS_VISIBLE = 'searchOverlayShown',

    /**
     * A global marker to show the page loader or not
     */
    APP_LOADER_SHOW = 'showAppLoader',

    /**
     * True if the branch search map should be rendered in select mode
     */
    BRANCH_SEARCH_IS_SELECT = 'branchSearchIsSelect',

    /**
     * True if the branch select is required from the same page as it would
     * normally link to. This is a marker for the branchSearch component to disable "going back"
     */
    BRANCH_SEARCH_IS_SAME_PAGE = 'branchSearchIsSamePage',

    /**
     * Holds the global job filter demand
     */
    JOB_FILTER_DEMAND = 'jobFilterDemand',

    /**
     * Holds the global states for shared job filter inputs
     */
    JOB_FILTER_STATE = 'jobFilterState',

    /**
     * The list of all saved jobs as job objects
     */
    JOB_SAVED_LIST = 'jobSaved',

    /*
    * overrides the page headline in LayoutHeader if set
     */
    PAGE_HEADLINE_OVERRIDE = 'pageHeadlineOverride',

    /*
    * overrides the page teaser image in LayoutHeader if set
     */

    PAGE_TEASER_IMAGE_OVERRIDE = 'pageTeaserImageOverride',

    /**
     * A media element that should be used as header video
     */
    PAGE_TEASER_VIDEO_OVERRIDE = 'pageTeaserVideoOverride',
    PAGE_TEASER_VIDEO_POSTER_OVERRIDE = 'pageTeaserVideoPosterOverride',

    /**
     * overrides the magazine headline to the currently selected magazine topic
     */
    MAGAZINE_HEADLINE_OVERRIDE = 'magazineHeadlineOverride',

    /**
     * overrides the magazine headline to the currently selected magazine topic
     */
    CARE_HEADLINE_OVERRIDE = 'careHeadlineOverride',

    /**
     * Sets the component shown in the right slide in menu
     * If you set the component the menu will automatically open itself
     */
    FIXED_ACTION_BUTTON_MENU_COMPONENT = 'fixedActionButtonMenuComponent',

    /**
     * The last typed chat bot messages to keep them even if the window was closed
     */
    CHAT_BOT_MESSAGES = 'chatBotMessages',

    /**
     * Stores the current page for the menu service -> this is used to make the currentPage property observable by vue.
     * @internal
     */
    CURRENT_PAGE = 'menuService:currentPage'
}
