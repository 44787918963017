var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "app-sidebar-container",
        { attrs: { context: _vm.pageContext } },
        [
          _c("router-view"),
          _vm._v(" "),
          !_vm.hideSubPageElementIds.includes(_vm.pageContext.id)
            ? _c("sub-page-listing")
            : _vm._e(),
          _vm._v(" "),
          _c("footer-menu", { attrs: { context: _vm.pageContext } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("AppLoader", { attrs: { context: _vm.pageContext } }),
      _vm._v(" "),
      _vm.breakpoint.is(">", "md") ? _c("awards") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }