var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shareButton" },
    [
      _c(
        "button",
        _vm._b(
          {
            staticClass: "shareButton__button",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          "button",
          _vm.iconHoverContainerProps(),
          false
        ),
        [
          _c(
            "icon",
            _vm._b(
              { staticClass: "shareButton__icon", attrs: { icon: "share" } },
              "icon",
              _vm.iconHoverProps(),
              false
            )
          ),
          _vm._v(" "),
          _c("span", { staticClass: "shareButton__label" }, [
            _vm._v(_vm._s(_vm.$t("jobSearch.actions.share"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("share-modal", {
        attrs: { text: _vm.title, link: _vm.url, type: _vm.type },
        model: {
          value: _vm.modalOpen,
          callback: function ($$v) {
            _vm.modalOpen = $$v
          },
          expression: "modalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }