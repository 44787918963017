
















































































import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {inflectToSlug} from '@labor-digital/helferlein/lib/Strings/Inflector/inflectToSlug';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {scrollToTopOf} from '@labor-digital/helferlein/lib/Ui/scrollToTopOf';
import {MainMenuLink} from '../../../Interface/Links';
import {HyphenFormatter} from '../../../Service/HyphenFormatter';
import {MenuService} from '../../../Service/MenuService';
import Btn from '../../Misc/Btn/Btn.vue';
import ImageTag from '../../Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'SubPageListing',
    components: {LinkWrap, ImageTag, Btn},
    props: {
        identifier: {
            default: null,
            type: Number
        },
        links: {
            default: null,
            type: Array
        },
        directLinks: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        items(): Array<MainMenuLink>
        {
            if (!isEmpty(this.links)) {
                return map(this.links, (i) => {
                    i = {...i};
                    i.slug = this.directLinks ? i.slug : inflectToSlug(i.title + '-' + i.id + '-' + this.identifier);
                    return i;
                });
            }

            const page = MenuService.currentPage;
            if (isEmpty(page) || !isArray(page.children)) {
                return [];
            }

            // Inject a slug to use as readable id
            return map(page.children, (i) => {
                i = {...i};
                i.slug = this.directLinks ? i.href : inflectToSlug(i.title + '-' + i.id);
                return i;
            });
        }
    },
    methods: {
        onIndexItemClick(item: MainMenuLink): void
        {
            const el = this.$refs[item.id];
            if (isEmpty(el)) {
                return;
            }
            scrollToTopOf(el[0], {offset: 200});
        },
        formatFancyText(item: MainMenuLink): string
        {
            return HyphenFormatter.hyphen(item.title + '');
        }
    }
};
