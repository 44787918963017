/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.11.27 at 10:04
 */
import {forEach, hasPath, isEmpty, PlainObject} from '@labor-digital/helferlein';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {Branch} from '../Interface/Branch';
import {Job} from '../Interface/Job';
import {MenuService} from './MenuService';

export class JsonLdService
{
    protected static _context: PageContext;

    protected static get baseUrl(): string
    {
        return this._context.baseUrl.replace(/\/$/, '');
    }

    public static initialize(context: AppContext): void
    {
        this._context = context.pageContext;
    }

    public static makeOrganization(): PlainObject
    {
        if (!hasPath(this._context, 'commonElements.config.organization')) {
            return {};
        }
        const organization: PlainObject = this._context.commonElements.config.organization;
        const address: PlainObject = organization.address ?? {};

        return {
            vmid: 'jsonLdOrganization',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org/',
                '@type': 'Organization',
                name: organization.name,
                legalName: organization.name,
                url: this._context.siteUrl,
                logo: organization.logo ?? '',
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: address.street + ' ' + address.streetNo,
                    addressLocality: address.city,
                    postalCode: address.zip,
                    addressCountry: address.countryCode
                },
                sameAs: organization.links
            }
        };
    }

    public static makeEmploymentAgency(branch: Branch): PlainObject
    {
        if (!hasPath(this._context, 'commonElements.config.organization')) {
            return {};
        }
        const organization: PlainObject = this._context.commonElements.config.organization;

        return {
            vmid: 'jsonLdEmploymentAgency',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org/',
                '@type': 'EmploymentAgency',
                name: branch.name,
                legalName: branch.name,
                openingHours: 'Mo,Tu,We,Th,Fr 8:00-17:00',
                url: this._context.siteUrl,
                logo: organization.logo ?? '',
                email: branch.email,
                telephone: branch.phone,
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: branch.address,
                    addressLocality: branch.city,
                    postalCode: branch.zip,
                    addressCountry: 'de'
                },
                sameAs: organization.links
            }
        };
    }

    public static makeBreadcrumb(altTitle?: string): PlainObject
    {
        const items = [];
        const baseUrl = this.baseUrl;
        let position = 1;
        let lastItem = null;
        forEach(MenuService.rootLine, item => {
            lastItem = {
                '@type': 'ListItem',
                position: position++,
                name: item.title,
                item: baseUrl + item.slug
            };
            items.push(lastItem);
        });

        // Update the last child
        if (lastItem !== null) {
            lastItem.name = altTitle ?? lastItem.name;
            lastItem.item = baseUrl + this._context.router.currentRoute.path;
        }

        return {
            vmid: 'jsonLdBreadcrumb',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org/',
                '@type': 'BreadcrumbList',
                itemListElement: items
            }
        };
    }

    public static makeFaq(tabs: Array<PlainObject>): PlainObject
    {
        const items = [];
        forEach(tabs, (tab) => {
            forEach(tab.contents, item => {
                items.push({
                    '@type': 'Question',
                    name: item.title,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: item.bodyText
                    }
                });
            });
        });

        return {
            vmid: 'jsonLdFaq',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org/',
                '@type': 'FAQPage',
                mainEntity: items
            }
        };
    }

    public static makeBlogPost(title: string, description: string, imageUrl?: string): PlainObject
    {
        const image = isEmpty(imageUrl) ? {} : {
            image: {
                '@type': 'ImageObject',
                'url': imageUrl
            }
        };

        return {
            vmid: 'jsonLdMagazine',
            type: 'application/ld+json',
            json: {
                '@context': 'https://schema.org/',
                '@type': 'BlogPosting',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id': this.baseUrl + this._context.router.currentRoute.path
                },
                headline: title,
                description: description,
                ...image,
                author: this.makeOrganization().json,
                publisher: this.makeOrganization().json
            }
        };
    }

    public static makeJobOffer(job: Job, description: string = null): PlainObject
    {
        let json = {
            '@context': 'https://schema.org/',
            '@type': 'JobPosting',
            directApply: false,
            title: job.title,
            description: description ?? job.teaserText,
            datePosted: job.from,
            employmentType: job.type === 'Vollzeit' ? 'FULL_TIME' : 'PART_TIME',
            workHours: job.type,
            validThrough: job.validThrough,
            hiringOrganization: this.makeOrganization().json,
            jobLocation: {
                '@type': 'Place',
                address: {
                    '@type': 'PostalAddress',
                    addressLocality: job.city,
                    addressRegion: job.region,
                    postalCode: job.zip,
                    addressCountry: job.country
                },
                geo: {
                    '@type': 'GeoCoordinates',
                    latitude: job.latitude,
                    longitude: job.longitude
                }
            },
            responsibilities: job.responsibilities,
            experienceRequirements: job.requirements,
            identifier: {
                '@type': 'PropertyValue',
                name: this.makeOrganization().json?.name ?? 'persona service AG & Co. KG',
                value: job.id
            }
        };

        if (job.salary) {
            const regex = /[+-]?\d+(\.\d+)?/g;
            const salary = job.salary;
            const unitText = salary.includes('Stunde') ? 'HOUR' : salary.includes('Monat') ? 'MONTH' : 'YEAR';
            const cleanSalary = salary.replace('.', '').replace('\u20ac', '').replace(',', '.');
            const [min, max] = cleanSalary.match(regex).map(function (v) { return parseFloat(v); });
            json['baseSalary'] = {
                '@type': 'MonetaryAmount',
                currency: 'EUR',
                value: {
                    '@type': 'QuantitativeValue',
                    maxValue: max,
                    minValue: min,
                    unitText: unitText
                }
            };
        }


        return {
            vmid: 'jsonLdJob',
            type: 'application/ld+json',
            json
        };
    }

    public static makeJobList(jobs: Array<Job>, city: string): PlainObject
    {
        let items = [];
        forEach(jobs, (job) => {
            items.push({
                '@type': 'ListItem',
                'item': this.makeJobOffer(job).json
            });
        });

        const list = {
            '@context': 'https://schema.org/',
            '@type': 'OfferCatalog',
            'name': 'Stellenausschreibungen ' + city,
            'itemListElement': items
        };

        return {
            vmid: 'jsonLdJob',
            type: 'application/ld+json',
            json: list
        };
    }
}
