/*
 * Copyright 2021 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2021.01.16 at 16:28
 */

import {isUndefined, PlainObject} from '@labor-digital/helferlein';

declare global
{
    interface Window
    {
        fbq?: Function
    }
}

export class FbPixelService
{

    /**
     * Emits a facebook event
     * @param message
     */
    public static track(message: string): void
    {
        this.emit(message);
    }

    /**
     * Emits a facebook event for the jobAlert subscription
     */
    public static subscribe(): void
    {
        this.emit('Subscribe', 'track', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
    }

    /**
     * Internal helper to emit a facebook event when the tracker was loaded
     * @param message
     * @param type
     * @param meta
     */
    public static emit(message: string, type?: string, meta?: PlainObject): void
    {
        if (!isUndefined(window.fbq)) {
            window.fbq(
                type ?? 'track',
                message,
                meta ?? undefined
            );
        }
    }
}
