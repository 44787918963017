













































import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {JobService} from '../../../../Service/JobService';
import SelectBox from '../../../Form/SelectBox/SelectBox.vue';
import Icon from '../../../Misc/Icon/Icon.vue';
import JobFilterMixin from '../../JobFilter/Type/JobFilterMixin';

export default {
    name: 'JobAlertLocation',
    components: {SelectBox, Icon},
    mixins: [JobFilterMixin],
    props: {
        error: String,
        required: {
            default: false,
            type: Boolean
        },
        readonly: false
    },
    data()
    {
        return {
            location: null
        };
    },
    computed: {
        state()
        {
            return JobService.state.location;
        },
        noDataLabel(): string
        {
            if (this.state.showSpinner) {
                return 'jobFilter.form.location.running';
            }
            return this.state.tooShort ?
                'jobFilter.form.location.tooShort' :
                'jobFilter.form.location.noResults';
        }
    },
    methods: {
        /**
         * An internal helper to resolve the auto-complete location items for the given query string
         * It will automatically update all props to show the spinner or error messages
         * @param query
         */
        onSearch(query: string): Promise<any>
        {
            // Validate the query length
            query = (
                query + ''
            ).trim();
            this.state.tooShort = query.length < 3;
            if (this.state.tooShort) {
                return Promise.resolve();
            }

            // Start the spinner
            this.state.showSpinner = true;

            // Require the location results
            return debouncePromise('jobSearchLocation',
                () => JobService.context.axios.get('/location/geocode/' + query), 300, true)
                .then(response => {
                    this.state.items = response.data;
                    this.state.showSpinner = false;
                });
        },

        /**
         * Triggered when the value was updated, and we have to update the demand
         * @param val
         */
        onInput(val)
        {
            this.$emit('input', isEmpty(val) ? null : val);
        }
    }
};
