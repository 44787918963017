



































































































import {forEach, getPath} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isObject} from '@labor-digital/helferlein/lib/Types/isObject';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import Headline from '../../../ContentElement/Persona/Headline/Headline.vue';
import {Campaign} from '../../../Interface/Campaign';
import LanguageSwitchMixin from '../../../Mixin/LanguageSwitchMixin';
import {MenuService} from '../../../Service/MenuService';
import ImageSlider from '../../Misc/Image/ImageSlider/ImageSlider.vue';
import ImageTag from '../../Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import Breadcrumb from '../Breadcrumb/Breadcrumb.vue';

export interface LayoutHeaderModuleDefinition
{
    key: 'applicants' | 'companies';
    component: any;
    props: PlainObject;
}

export default {
    name: 'LayoutHeader',
    components: {
        ImageSlider,
        LinkWrap,
        ImageTag,
        Breadcrumb, Headline,
        VideoVimeo: () => import('../../Misc/Video/VideoVimeo/VideoVimeo.vue'),
        VideoYoutube: () => import('../../Misc/Video/VideoYoutube/VideoYoutube.vue'),
        VideoEmbed: () => import('../../Misc/Video/VideoEmbed/VideoEmbed.vue')
    },
    mixins: [LanguageSwitchMixin],
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            modules: [] as Array<LayoutHeaderModuleDefinition>,
            bpDefinitions: {sm: 'layoutHeaderSm', xs: 'xs'},
            preloadDefinitions: {
                767: 'layoutHeaderSm',
                9999: 'layoutHeader'
            },
            campaignHover: false
        };
    },
    metaInfo()
    {
        let preloadLinks = [];
        forEach(this.media, image => {
            forEach(this.preloadDefinitions, (definition, key) => {
                preloadLinks.push({
                    'rel': 'preload',
                    'href': image.isStatic ? image.url : this.buildUrl(image, definition),
                    'as': 'image',
                    'media': '(max-width: ' + key + 'px)'
                });
            });
        });


        return {
            link: preloadLinks
        };
    },
    methods: {

        /**
         * Builds the url for a single image with the given definition key
         * @param image
         * @param definition
         */
        buildUrl(image: PlainObject, definition: string): string
        {
            let url = image.url;
            url += '&definition=' + definition;
            return url;
        }
    },
    computed: {
        isDefaultLayout(): boolean
        {
            return this.context.layout === 'default' || this.context.layout === '2';
        },
        isEmpty(): boolean
        {
            return !this.hasMedia && isEmpty(this.pageHeadline);
        },
        isDesktop(): boolean
        {
            const breakpoint = this.$root.appContext.store.get(AppStoreKeys.BREAKPOINT);
            return breakpoint.is('>', 'md');
        },
        hasMedia(): boolean
        {
            return this.hasImage || this.isVideoFile || this.isYouTube || this.isVimeo;
        },
        campaign(): Campaign | null
        {
            let campaigns: Array<Campaign> = {...getPath(this.context, 'commonElements.config.campaign')};
            let activeCampaign = null;
            let active = false;

            forEach(campaigns, campaign => {
                if (active) {
                    return;
                }
                const exclude = campaign.campaignExclude.split(',');
                if (exclude && exclude.indexOf(this.context.id) !== -1 || !MenuService.isLanguageCode('de')) {
                    active = false;
                    return;
                }

                switch (campaign.campaignActive) {
                    case 'all':
                        active = true;
                        activeCampaign = active ? campaign : null;
                        break;
                    case 'applicants':
                        active = MenuService.isForApplicants();
                        activeCampaign = active ? campaign : null;
                        break;
                    case 'company':
                        active = MenuService.isForCompanies();
                        activeCampaign = active ? campaign : null;
                        break;
                    default:
                        active = false;
                }
            });
            return {
                ...activeCampaign,
                active
            };
        },
        pageHeadline(): string
        {
            const headline = this.context.store.get(AppStoreKeys.PAGE_HEADLINE_OVERRIDE,
                    this.context.data.get('subtitle', ''));
            if (isEmpty(headline)) {
                return this.context.data.get('title', '');
            }
            return headline;
        },
        useVideoHeader(): boolean
        {
            // Always return true if we have a global override set
            if (this.context.store.get(AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE, false) !== false) {
                return true;
            }
            return this.context.data.get('useVideoHeader', false);
        },
        isVideoFile(): boolean
        {
            // Always return true if we have a global override set (@todo why do we do this twice?)
            if (this.context.store.get(AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE, false) !== false) {
                return true;
            }
            return !isEmpty(this.mediaVideo) && !this.isYouTube && !this.isVimeo;
        },
        isYouTube(): boolean
        {
            return this.mediaVideo.isYouTube === true;
        },
        isVimeo(): boolean
        {
            return this.mediaVideo.isVimeo === true;
        },
        isAutoplay(): boolean
        {
            return this.$router.currentRoute.path === MenuService.getHomeLink() ||
                   this.context.store.get(AppStoreKeys.BREAKPOINT).is('>', 'sm');
        },
        hasImage(): boolean
        {
            return !isEmpty(this.media);
        },
        hasBpDefinition(): boolean
        {
            console.log(isObject(this.media));
            return isObject(this.media) && !isEmpty(this.media.definitions);
        },
        media(): Array<PlainObject> | PlainObject
        {
            if (!this.useVideoHeader) {
                return this.context.store.get(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE,
                        this.context.data.get('media', [{}]));
            }
            return [];
        },
        mediaVideo(): PlainObject
        {
            if (this.useVideoHeader) {
                const media =
                        this.context.store.get(AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE,
                                this.context.data.get('mediaVideo', {}));
                if (media === null) {
                    return {};
                }
                return media.video;
            }
            return {};
        },
        mediaVideoPoster(): string
        {
            if (this.isVideoFile) {
                return this.context.store.get(AppStoreKeys.PAGE_TEASER_VIDEO_POSTER_OVERRIDE,
                        this.context.data.get('mediaVideoPoster', {})).url ?? '';
            }
            return '';
        }
    },
    mounted()
    {
        setTimeout(() => {
            let modules: Array<LayoutHeaderModuleDefinition> = [];

            //Temporally disable ukraine job search
            if (!MenuService.isLanguageCode('uk')) {
                // Add job search
                if (MenuService.isHomepageForApplicants() || !this.isMainLanguage && this.isDefaultLayout) {
                    let HeaderFindJobModule = () => import('./Module/FindJobModule/FindJobModule.vue');
                    modules.push({
                        key: 'applicants',
                        component: HeaderFindJobModule,
                        props: {
                            link: this.context.data.get('jobListPageLink', '')
                        }
                    } as any);
                }

                // Add request employees form
                if (MenuService.isHomepageForCompanies()) {
                    let HeaderRequestEmployeesModule = () => import('./Module/RequestEmployeesModule/RequestEmployeesModule.vue');
                    modules.push({
                        key: 'companies',
                        component: HeaderRequestEmployeesModule,
                        props: {
                            context: this.context
                        }
                    } as any);
                }
            }

            this.modules = modules;

        }, 200);
    }
};
