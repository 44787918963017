
























import {MenuService} from '../../../../Service/MenuService';
import LinkWrap from '../LinkWrap/LinkWrap.vue';

export default {
    name: 'HomeLinkWrap',
    components: {LinkWrap},
    computed: {
        homeLink(): string
        {
            return MenuService.getHomeLink();
        }
    }
};
