var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "emptyListNotification",
      class: {
        "emptyListNotification--hasGreenBackground": _vm.hasGreenBackground,
      },
    },
    [
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.$t("emptyListNotification.noResults")))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }