var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search", on: { click: _vm.onSearchClick } },
    [
      _c("div", { staticClass: "search__overlay" }),
      _vm._v(" "),
      _c(
        "nav",
        {
          staticClass: "search__navigation",
          attrs: { "data-stop-body-scrolling-fixed": "half" },
        },
        [
          _c("div", { staticClass: "upper" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("logo"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("label", { attrs: { for: "search-query" } }, [
                      _vm._v(_vm._s(_vm.$t("search.form.label"))),
                    ]),
                    _vm._v(" "),
                    _c("input-field", {
                      attrs: {
                        id: "search-query",
                        "show-spinner": _vm.isSearching,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "beforeInput",
                          fn: function () {
                            return [_c("icon", { attrs: { icon: "search" } })]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query,
                        callback: function ($$v) {
                          _vm.query = $$v
                        },
                        expression: "query",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "a",
                    { on: { click: _vm.close } },
                    [
                      _c("icon", { attrs: { icon: "close" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("navigation.burger.close.text"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "search__result",
          attrs: {
            "data-stop-body-scrolling-fixed": "half",
            "data-infinite-wrapper": "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search__overflow" },
            [
              _vm.showFeatured
                ? _c("featured", { attrs: { context: _vm.context } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "tabs",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showFeatured,
                      expression: "!showFeatured",
                    },
                  ],
                  attrs: { items: _vm.tabs, "item-label": "label" },
                  on: { open: _vm.onTabChange },
                  scopedSlots: _vm._u([
                    {
                      key: "afterLabel",
                      fn: function (slotProps) {
                        return [
                          _c("span", { staticClass: "count" }, [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.getTabCount(slotProps.item)) +
                                ")"
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[0].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 0
                          ? _c("list", {
                              attrs: {
                                context: _vm.context,
                                items: _vm.result,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[1].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 1
                          ? _c("list", {
                              attrs: {
                                context: _vm.context,
                                items: _vm.result,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[2].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 2
                          ? _c("list", {
                              attrs: {
                                context: _vm.context,
                                items: _vm.result,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[3].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 3 && _vm.result.length > 0
                          ? _c(
                              "green-container",
                              { attrs: { "add-container-styles": false } },
                              [
                                _c("job-item-list", {
                                  attrs: {
                                    jobs: _vm.result,
                                    "search-layout": true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[4].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 4
                          ? _c("list", {
                              attrs: {
                                context: _vm.context,
                                items: _vm.result,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: _vm.tabs[5].label }, [
                    _c(
                      "div",
                      [
                        _vm.tabIndex === 5
                          ? _c("list", {
                              attrs: {
                                context: _vm.context,
                                items: _vm.result,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("infinite-loading", {
                attrs: {
                  identifier: _vm.infiniteId,
                  "force-use-infinite-wrapper": true,
                },
                on: { infinite: _vm.onInfinite },
                scopedSlots: _vm._u([
                  {
                    key: "spinner",
                    fn: function () {
                      return [
                        _vm.showSpinner
                          ? _c("infinity-loading-spinner")
                          : _c("span"),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "empty-list-notification",
                          { attrs: { "has-green-background": true } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("search.results.none")) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-more",
                    fn: function () {
                      return [
                        _vm._v("\n                     \n                "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }