





























import {Job} from '../../../../Interface/Job';
import {SavedJobService} from '../../../../Service/SavedJobService';
import Icon from '../../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../../Misc/Icon/IconHoverMixin';

export default {
    name: 'SaveJobAction',
    components: {Icon},
    mixins: [IconHoverMixin],
    props: {
        job: {
            type: Object,
            required: true
        } as Job
    },
    computed: {
        isSaved(): boolean
        {
            return SavedJobService.getSavedJobs().has(this.job.id);
        },
        label(): string
        {
            return this.isSaved ? 'jobSearch.actions.unsave' : 'jobSearch.actions.save';
        }
    },
    methods: {
        onClick()
        {
            if (this.isSaved) {
                SavedJobService.forgetJob(this.job);
            } else {
                SavedJobService.saveJob(this.job);
            }
        }
    }
};
