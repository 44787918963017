var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "networks" },
    _vm._l(_vm.networks, function (network) {
      return _c(
        "LinkWrap",
        {
          key: network.id,
          staticClass: "networks__network",
          attrs: { link: network.href, "link-props": { title: network.title } },
        },
        [
          network.fields && network.fields.media && network.fields.media[0]
            ? _c("ImageTag", { attrs: { image: network.fields.media[0] } })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }