














































import {isEmpty} from '@labor-digital/helferlein';
import {filter} from '@labor-digital/helferlein/lib/Lists/filter';
import BaseSelectBox from '@labor-digital/vuetiful/src/Components/SelectBox/SelectBox';
import Icon from '../../Misc/Icon/Icon.vue';
import IconHoverMixin from '../../Misc/Icon/IconHoverMixin';
import Spinner from '../../Misc/Spinner/Spinner.vue';

export default {
    name: 'SelectBox',
    mixins: [IconHoverMixin],
    components: {Icon, Spinner, BaseSelectBox},
    props: {
        ...BaseSelectBox.props,
        showSpinner: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        hideArrow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasValue(): boolean
        {
            return !isEmpty(this.value);
        },
        slots()
        {
            // Only pass on slots that we don't overwrite
            return filter(this.$scopedSlots, (v, k) => {
                return k !== 'input-end';
            });
        }
    },
    methods: {
        onClear()
        {
            this.$emit('input', null);
            this.$emit('selected', null);
        }
    }

};
