var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-select-box",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "allow-mobile-scroll": false,
            "scroll-to-item-if-needed": false,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "input-end",
                fn: function () {
                  return [
                    _vm.showSpinner
                      ? _c(
                          "div",
                          { staticClass: "selectBox__spinner" },
                          [_c("spinner")],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("input-end"),
                    _vm._v(" "),
                    _vm.clearable && _vm.hasValue
                      ? _c(
                          "icon",
                          _vm._b(
                            {
                              staticClass:
                                "selectBox__icon selectBox__icon--clear",
                              attrs: { icon: "check" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onClear.apply(null, arguments)
                                },
                              },
                            },
                            "icon",
                            _vm.iconHoverProps(),
                            false
                          )
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hideArrow
                      ? _c(
                          "icon",
                          _vm._b(
                            {
                              staticClass:
                                "selectBox__icon selectBox__icon--default",
                              attrs: { icon: "arrowDown" },
                            },
                            "icon",
                            _vm.iconHoverProps(),
                            false
                          )
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              _vm._l(_vm.slots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "base-select-box",
        Object.assign(
          {},
          _vm.$props,
          _vm.$attrs,
          _vm.iconHoverContainerProps()
        ),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }