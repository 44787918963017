var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footerMenu" }, [
    _vm.isMainLanguage && !_vm.isLandingpage
      ? _c("div", { staticClass: "footerMenu footerMenu--upper" }, [
          _c("div", { staticClass: "footerMenu__inner" }, [
            _c(
              "div",
              { staticClass: "footerMenu__grid" },
              [
                _vm.footerLayout === "business"
                  ? _c(
                      "div",
                      { staticClass: "footerMenu__col" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "footerMenu__title",
                            style: _vm.titleStyle,
                            attrs: { "data-ref": "footer-menu-column-title" },
                          },
                          [_vm._v(_vm._s(_vm.$t("footer.headline.contact")))]
                        ),
                        _vm._v(" "),
                        _c("current-branch"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.footer.columns, function (col, k) {
                  return _c("div", { staticClass: "footerMenu__col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "footerMenu__title",
                        style: _vm.titleStyle,
                        attrs: { "data-ref": "footer-menu-column-title" },
                      },
                      [_vm._v(_vm._s(col.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "footerMenu__menu" },
                      _vm._l(col.children, function (item) {
                        return _c(
                          "li",
                          { staticClass: "footerMenu__link" },
                          [
                            _c(
                              "link-wrap",
                              {
                                attrs: { link: item.slug },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.limitLinkLabel(item.title)
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.limitLinkLabel(item.title)) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footerMenu__grid" }, [
              _vm.footerLayout === "default"
                ? _c(
                    "div",
                    { staticClass: "footerMenu__col" },
                    [
                      _c("span", { staticClass: "footerMenu__title" }, [
                        _vm._v(_vm._s(_vm.$t("footer.headline.contact"))),
                      ]),
                      _vm._v(" "),
                      _c("current-branch"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.footer.relevant
                ? _c(
                    "div",
                    { staticClass: "footerMenu__col", class: _vm.classes },
                    [
                      _c("span", { staticClass: "footerMenu__title" }, [
                        _vm._v(_vm._s(_vm.$t("footer.headline.topics"))),
                      ]),
                      _vm._v(" "),
                      _c("link-cloud", {
                        attrs: { links: _vm.footer.relevant.children },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "footerMenu footerMenu--lower" }, [
      _c("div", { staticClass: "footerMenu__inner" }, [
        _c("div", { staticClass: "footerMenu__grid" }, [
          _c(
            "div",
            { staticClass: "footerMenu__col footerMenu__col--wide" },
            _vm._l(_vm.metaFooter, function (meta) {
              return _c(
                "span",
                [
                  _c(
                    "link-wrap",
                    {
                      staticClass:
                        "footerMenu__link footerMenu__link--marginRight",
                      attrs: { link: meta.href },
                    },
                    [_vm._v(_vm._s(meta.title))]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footerMenu__col" },
            [
              !_vm.isLandingpage
                ? _c("FooterMenu_Networks", { attrs: { context: _vm.context } })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "footerMenu__metaText" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\t© " +
                    _vm._s(_vm.year) +
                    " persona service AG & Co. KG"
                ),
                _c("br"),
                _vm._v(
                  "\n\t\t\t\t\t\t\t" +
                    _vm._s(_vm.$t("rightsReserved")) +
                    "\n\t\t\t\t\t\t"
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }