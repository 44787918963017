var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-input-field",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "clearIcon",
                fn: function () {
                  return [_c("icon", { attrs: { icon: "check" } })]
                },
                proxy: true,
              },
              {
                key: "beforeClearIcon",
                fn: function () {
                  return [
                    _vm._t("beforeClearIcon"),
                    _vm._v(" "),
                    _vm.showSpinner
                      ? _c(
                          "div",
                          { staticClass: "inputField__spinner" },
                          [_c("spinner")],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              _vm._l(_vm.slots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "base-input-field",
        Object.assign({}, _vm.$props, _vm.$attrs),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }