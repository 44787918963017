var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "shareModal",
      model: {
        value: _vm.modalOpen,
        callback: function ($$v) {
          _vm.modalOpen = $$v
        },
        expression: "modalOpen",
      },
    },
    [
      _c("div", { staticClass: "shareModal__modalContent" }, [
        _c(
          "a",
          {
            staticClass: "resp-sharing-button__link",
            attrs: {
              href: "https://facebook.com/sharer/sharer.php?u=" + _vm.url,
              target: "_blank",
              rel: "noopener",
              "aria-label": "Facebook",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "resp-sharing-button resp-sharing-button--facebook resp-sharing-button--medium",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button__icon resp-sharing-button__icon--solidcircle",
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 24 24",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm3.6 11.5h-2.1v7h-3v-7h-2v-2h2V8.34c0-1.1.35-2.82 2.65-2.82h2.35v2.3h-1.4c-.25 0-.6.13-.6.66V9.5h2.34l-.24 2z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v("\n                Facebook\n            "),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href:
                    "https://twitter.com/intent/tweet/?text=" +
                    _vm.message +
                    "&url=" +
                    _vm.url,
                  target: "_blank",
                  rel: "noopener",
                  "aria-label": "Twitter",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--twitter resp-sharing-button--medium",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solidcircle",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm5.26 9.38v.34c0 3.48-2.64 7.5-7.48 7.5-1.48 0-2.87-.44-4.03-1.2 1.37.17 2.77-.2 3.9-1.08-1.16-.02-2.13-.78-2.46-1.83.38.1.8.07 1.17-.03-1.2-.24-2.1-1.3-2.1-2.58v-.05c.35.2.75.32 1.18.33-.7-.47-1.17-1.28-1.17-2.2 0-.47.13-.92.36-1.3C7.94 8.85 9.88 9.9 12.06 10c-.04-.2-.06-.4-.06-.6 0-1.46 1.18-2.63 2.63-2.63.76 0 1.44.3 1.92.82.6-.12 1.95-.27 1.95-.27-.35.53-.72 1.66-1.24 2.04z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                Twitter\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href: "mailto:?subject=" + _vm.message + "&body=" + _vm.url,
                  target: "_self",
                  rel: "noopener",
                  "aria-label": "E-Mail",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--email resp-sharing-button--medium",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solidcircle",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm8 16c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2v8z",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M17.9 8.18c-.2-.2-.5-.24-.72-.07L12 12.38 6.82 8.1c-.22-.16-.53-.13-.7.08s-.15.53.06.7l3.62 2.97-3.57 2.23c-.23.14-.3.45-.15.7.1.14.25.22.42.22.1 0 .18-.02.27-.08l3.85-2.4 1.06.87c.1.04.2.1.32.1s.23-.06.32-.1l1.06-.9 3.86 2.4c.08.06.17.1.26.1.17 0 .33-.1.42-.25.15-.24.08-.55-.15-.7l-3.57-2.22 3.62-2.96c.2-.2.24-.5.07-.72z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                E-Mail\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href: "whatsapp://send?text=" + _vm.message + "%20" + _vm.url,
                  target: "_blank",
                  rel: "noopener",
                  "aria-label": "WhatsApp",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--medium",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solidcircle",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              height: "24",
                              width: "24",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm0 3.8c2.2 0 4.2 0.9 5.7 2.4 1.6 1.5 2.4 3.6 2.5 5.7 0 4.5-3.6 8.1-8.1 8.1-1.4 0-2.7-0.4-3.9-1l-4.4 1.1 1.2-4.2c-0.8-1.2-1.1-2.6-1.1-4 0-4.5 3.6-8.1 8.1-8.1zm0.1 1.5c-3.7 0-6.7 3-6.7 6.7 0 1.3 0.3 2.5 1 3.6l0.1 0.3-0.7 2.4 2.5-0.7 0.3 0.099c1 0.7 2.2 1 3.4 1 3.7 0 6.8-3 6.9-6.6 0-1.8-0.7-3.5-2-4.8s-3-2-4.8-2zm-3 2.9h0.4c0.2 0 0.4-0.099 0.5 0.3s0.5 1.5 0.6 1.7 0.1 0.2 0 0.3-0.1 0.2-0.2 0.3l-0.3 0.3c-0.1 0.1-0.2 0.2-0.1 0.4 0.2 0.2 0.6 0.9 1.2 1.4 0.7 0.7 1.4 0.9 1.6 1 0.2 0 0.3 0.001 0.4-0.099s0.5-0.6 0.6-0.8c0.2-0.2 0.3-0.2 0.5-0.1l1.4 0.7c0.2 0.1 0.3 0.2 0.5 0.3 0 0.1 0.1 0.5-0.099 1s-1 0.9-1.4 1c-0.3 0-0.8 0.001-1.3-0.099-0.3-0.1-0.7-0.2-1.2-0.4-2.1-0.9-3.4-3-3.5-3.1s-0.8-1.1-0.8-2.1c0-1 0.5-1.5 0.7-1.7s0.4-0.3 0.5-0.3z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                WhatsApp\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href:
                    "https://telegram.me/share/url?text=" +
                    _vm.message +
                    "&url=" +
                    _vm.url,
                  target: "_blank",
                  rel: "noopener",
                  "aria-label": "Telegram",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--telegram resp-sharing-button--medium",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solidcircle",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M12 23.5c6.35 0 11.5-5.15 11.5-11.5S18.35.5 12 .5.5 5.65.5 12 5.65 23.5 12 23.5zM2.505 11.053c-.31.118-.505.738-.505.738s.203.62.513.737l3.636 1.355 1.417 4.557a.787.787 0 0 0 1.25.375l2.115-1.72a.29.29 0 0 1 .353-.01L15.1 19.85a.786.786 0 0 0 .746.095.786.786 0 0 0 .487-.573l2.793-13.426a.787.787 0 0 0-1.054-.893l-15.568 6z",
                                "fill-rule": "evenodd",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                Telegram\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href:
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    _vm.url +
                    "&title=" +
                    _vm.message,
                  target: "_blank",
                  rel: "noopener",
                  "aria-label": "Share on LinkedIn",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solid",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                LinkedIn\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isJob
          ? _c(
              "a",
              {
                staticClass: "resp-sharing-button__link",
                attrs: {
                  href:
                    "https://www.xing.com/app/user?op=share;url=" +
                    _vm.url +
                    ";title=" +
                    _vm.message,
                  target: "_blank",
                  rel: "noopener",
                  "aria-label": "Share on XING",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "resp-sharing-button resp-sharing-button--xing resp-sharing-button--large",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "resp-sharing-button__icon resp-sharing-button__icon--solid",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v("\n                XING\n            "),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }