var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLabel
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "Btn",
            {
              attrs: {
                type: _vm.type,
                href: _vm.href,
                "has-icon": _vm.hasIcon,
                "icon-position": _vm.iconPosition,
                "icon-svg-content": _vm.icon,
                "is-ghost": _vm.isGhost,
              },
            },
            [_vm._v(_vm._s(_vm.label) + "\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }