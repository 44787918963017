var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmpty
    ? _c("div", { staticClass: "layoutHeader" }, [
        _vm.hasMedia
          ? _c("div", { staticClass: "layoutHeader__media" }, [
              _c("div", { staticClass: "layoutHeader__mediaContainer" }, [
                _vm.isDesktop
                  ? _c(
                      "div",
                      { staticClass: "layoutHeader__mediaBackdrop" },
                      [
                        _vm.hasBpDefinition
                          ? _c(
                              "picture",
                              { staticClass: "layoutHeader__pictureBackdrop" },
                              [
                                _vm._l(
                                  _vm.media.definitions,
                                  function (source) {
                                    return _c(
                                      "source",
                                      _vm._b({}, "source", source, false)
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: { "object-fit": "cover" },
                                  attrs: {
                                    alt: _vm.media.alt,
                                    src: _vm.media.url,
                                    title: _vm.media.title,
                                  },
                                }),
                              ],
                              2
                            )
                          : _vm.hasImage
                          ? _c("image-slider", {
                              attrs: {
                                "bp-definitions": _vm.bpDefinitions,
                                images: _vm.media,
                                "lazy-loading": false,
                                "slide-duration": 6500,
                                definition: "layoutHeader",
                                "is-autoplay": "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isVideoFile
                          ? _c("img", { attrs: { src: _vm.mediaVideoPoster } })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "layoutHeader__mediaMain" },
                  [
                    _vm.hasBpDefinition
                      ? _c(
                          "picture",
                          { staticClass: "layoutHeader__picture" },
                          [
                            _vm._l(_vm.media.definitions, function (source) {
                              return _c(
                                "source",
                                _vm._b({}, "source", source, false)
                              )
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { "object-fit": "cover" },
                              attrs: {
                                alt: _vm.media.alt,
                                src: _vm.media.url,
                                title: _vm.media.title,
                              },
                            }),
                          ],
                          2
                        )
                      : _vm.hasImage
                      ? _c("image-slider", {
                          attrs: {
                            "bp-definitions": _vm.bpDefinitions,
                            images: _vm.media,
                            "lazy-loading": false,
                            "slide-duration": 6500,
                            definition: "layoutHeader",
                            "is-autoplay": "",
                          },
                        })
                      : _vm.isVideoFile
                      ? _c("video-embed", {
                          attrs: {
                            autoplay: _vm.isAutoplay,
                            "media-file": this.mediaVideo.videoId,
                            poster: _vm.mediaVideoPoster,
                          },
                        })
                      : _vm.isYouTube
                      ? _c("video-youtube", {
                          attrs: {
                            autoplay: _vm.isAutoplay,
                            "you-tube-id": this.mediaVideo.videoId,
                          },
                        })
                      : _vm.isVimeo
                      ? _c("video-vimeo", {
                          attrs: {
                            autoplay: _vm.isAutoplay,
                            "vimeo-id": this.mediaVideo.videoId,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.campaign.active
                ? _c(
                    "div",
                    { staticClass: "layoutHeader__campaign" },
                    [
                      _c(
                        "link-wrap",
                        {
                          staticClass: "layoutHeader__campaignImage",
                          attrs: { link: _vm.campaign.campaignLink },
                        },
                        [
                          _c("img", {
                            staticStyle: { "object-fit": "cover" },
                            attrs: {
                              alt: _vm.campaign.campaignText,
                              src: _vm.campaign.campaignImage,
                              loading: "lazy",
                            },
                            on: {
                              click: function ($event) {
                                _vm.campaignHover = false
                              },
                              mouseenter: function ($event) {
                                _vm.campaignHover = true
                              },
                              mouseleave: function ($event) {
                                _vm.campaignHover = false
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "layoutHeader__campaignFade" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.campaignHover,
                                  expression: "campaignHover",
                                },
                              ],
                              staticClass: "layoutHeader__campaignHover",
                              style: { background: _vm.campaign.campaignColor },
                            },
                            [
                              _c("img", {
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  alt: _vm.campaign.campaignText,
                                  src: _vm.campaign.campaignHover,
                                  loading: "lazy",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "link-wrap",
                                { attrs: { link: _vm.campaign.campaignLink } },
                                [_vm._v(_vm._s(_vm.campaign.campaignText))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "layoutHeader__campaignArrow",
                        class: {
                          "layoutHeader__campaignArrow--module":
                            _vm.modules.length > 0,
                          "layoutHeader__campaignArrow--open":
                            _vm.campaignHover,
                        },
                        style: { background: _vm.campaign.campaignColor },
                        on: {
                          click: function ($event) {
                            _vm.campaignHover = !_vm.campaignHover
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : !_vm.isDefaultLayout
          ? _c("div", {
              staticClass: "layoutHeader__media layoutHeader__media--noop",
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isDefaultLayout && !_vm.hasMedia
          ? _c("div", {
              staticClass: "layoutHeader__media layoutHeader__media--margin",
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.modules.length
          ? _c(
              "div",
              { staticClass: "layoutHeader__modules" },
              _vm._l(_vm.modules, function (module) {
                return _c(
                  module.component,
                  _vm._b(
                    { key: module.key, tag: "component" },
                    "component",
                    module.props,
                    false
                  )
                )
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "layoutHeader__container" }, [
          _c(
            "div",
            { staticClass: "layoutHeader__inner" },
            [
              _c("breadcrumb", { attrs: { context: _vm.context } }),
              _vm._v(" "),
              _c("h1", {
                staticClass: "layoutHeader__headline",
                class: { "layoutHeader__headline--small": _vm.isDefaultLayout },
                domProps: { innerHTML: _vm._s(_vm.pageHeadline) },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }