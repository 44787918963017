/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.05.08 at 10:57
 */

import {GenericStorage} from '@labor-digital/helferlein/lib/Entities/GenericStorage';
import {getLocalStorage} from '@labor-digital/helferlein/lib/Misc/getLocalStorage';
import {isNumeric} from '@labor-digital/helferlein/lib/Types/isNumeric';
import {scrollToTopOf} from '@labor-digital/helferlein/lib/Ui/scrollToTopOf';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {AppStoreKeys} from '../AppStoreKeys';
import {Branch} from '../Interface/Branch';
import {GeoCodeLocation} from '../Interface/GeoCodeLocation';
import {MenuService} from './MenuService';

export class BranchAndGeoLocationService
{

    /**
     * The app context object to resolve the store in
     */
    protected static _context: AppContext;

    /**
     * The storage object to persist the branch data in the local storage of the browser
     */
    protected static _localStorage: GenericStorage;

    /**
     * Initialize the service by injecting the app context
     * @param context
     */
    public static initialize(context: AppContext): Promise<void>
    {
        this._localStorage = getLocalStorage('branchAndGeoLocation');
        this._context = context;

        // Initialize the location
        context.store.set(AppStoreKeys.CURRENT_LOCATION, this._localStorage.get('location', null));

        if (this._context.isServer) {
            return Promise.resolve();
        }

        return this.findInitialBranch();
    }

    /**
     * Returns the currently active geo location of the user as lat and lon object
     * If we don't have a stored location and don't have a branch either NULL is returned
     */
    public static getCurrentLocation(): GeoCodeLocation | null
    {
        return this._context.store.get(AppStoreKeys.CURRENT_LOCATION);
    }

    /**
     * Used to update the current geo location for the user.
     * @param location
     */
    public static setCurrentLocation(location: GeoCodeLocation): void
    {
        this._localStorage.set('location', location);
        this._context.store.set(AppStoreKeys.CURRENT_LOCATION, location);
    }

    /**
     * Set's the currently displayed branch to the given input
     * The branch is persisted in the local storage of the user's browser
     * @param branch Either the branch object, or the id of a branch
     */
    public static setCurrentBranch(branch: Branch | number): Promise<void>
    {
        // Handle numeric branch
        if (isNumeric(branch)) {
            return this._context.resourceApi.getResource('branch', branch as number).then(branch => {
                this._localStorage.set('branch', branch.get('id'));
                return this.setCurrentBranch(branch.getAll() as Branch);
            }).catch(() => null);
        }

        // Check if the given branch is valid
        if (!isNumeric(branch.id)) {
            throw new Error('Invalid branch given! Only numbers or valid branch objects are allowed!');
        }

        // Store the branch object
        this._localStorage.set('branch', branch.id);
        this._context.store.set(AppStoreKeys.CURRENT_BRANCH, branch);

        // Update location if it is empty
        if (this.getCurrentLocation() === null) {
            this.setCurrentLocation({
                address: branch.city,
                latitude: branch.latitude,
                longitude: branch.longitude
            });
        }

        // Done
        return Promise.resolve();
    }

    /**
     * Returns either the currently selected branch object or null
     * if there is none
     */
    public static getCurrentBranch(): null | Branch
    {
        if (this._context.isServer) {
            return null;
        }
        return this._context.store.get(AppStoreKeys.CURRENT_BRANCH, null);
    }

    /**
     * Redirects the user to the branch map to select another branch
     */
    public static openSelectBranchPage(): void
    {
        this._context.store.set(AppStoreKeys.BRANCH_SEARCH_IS_SELECT, true);
        const targetPath = '/' + this.getBranchSearchLink().replace(/^(?:\/\/|[^/]+)*\//, '');

        // Check if we can navigate to the page or if we are currently there
        const router = this._context.pageContext.router;
        if (router.currentRoute.fullPath === targetPath) {
            scrollToTopOf(document.querySelector('#branch-search-map'));
            return;
        } else {
            router.push({path: targetPath, hash: '#branch-search-map'});
        }
    }

    /**
     * Returns the url to the branch map based on the current context
     */
    public static getBranchSearchLink(): string
    {
        return this._context.pageContext.linkRepository.get('branchList');
    }

    /**
     * Helper to select the correct branch link based on the current content context.
     * @param branch
     */
    public static getContextualBranchLink(branch: Branch): string
    {
        return MenuService.isForApplicants() ? branch.urlForApplicants : branch.urlForCompanies;
    }

    /**
     * Tries to find the initial branch when the service is initialized.
     */
    protected static findInitialBranch(): Promise<void>
    {
        // Try to load the current branch from the local storage
        const storedBranchId = this._localStorage.get('branch', null);
        if (storedBranchId !== null) {
            return this.setCurrentBranch(storedBranchId);
        }

        // Try to find the branch by the user's geo location
        return this._context.resourceApi.getResource('branch', 'byIpLocation')
                   .then(result => {
                       const branches = result.getAll();
                       if (branches.length !== 0) {
                           return this.setCurrentBranch(branches[0].getAll());
                       }
                   })
                   .catch(e => {
                       if (e.isAxiosError && e.response.status === 404) {
                           return null;
                       }
                       return this._context.errorHandler.emitError(
                           this._context.errorHandler.makeNetworkError(e)
                       );
                   });
    }
}
