var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b({ staticClass: "imageTag" }, "div", _vm.attr, false),
    [
      _vm.supportsPicture
        ? _c(
            "picture",
            { staticClass: "imageTag__picture" },
            [
              _vm._l(_vm.sourceList, function (source) {
                return _c("source", _vm._b({}, "source", source, false))
              }),
              _vm._v(" "),
              _vm.supportsPicture
                ? _c("img", {
                    staticStyle: { "object-fit": "cover" },
                    attrs: {
                      src: _vm.src,
                      alt: _vm.alt,
                      title: _vm.title,
                      loading: _vm.lazyLoading,
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _c(
            "div",
            _vm._b(
              { staticClass: "imageTag__fallback" },
              "div",
              _vm.fallbackAttr,
              false
            ),
            [_c("div", { staticClass: "imageTag__fallbackSize" })]
          ),
      _vm._v(" "),
      _vm.desc !== ""
        ? _c("div", { staticClass: "imageTag__desc" }, [
            _vm._v("\n        " + _vm._s(_vm.desc) + "\n    "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }