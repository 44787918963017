var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        _vm.tag,
        {
          tag: "component",
          staticClass: "headline",
          class: "headline--" + _vm.tag,
          attrs: { id: _vm.id },
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }