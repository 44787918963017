




























import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import Spinner from '../../Misc/Spinner/Spinner.vue';

export default {
    name: 'AppLoader',
    components: {Spinner},
    props: {
        context: null as PageContext
    },
    data()
    {
        return {
            show: false,
            loaderTimeout: 0
        };
    },
    created(): void
    {
        if (this.context === null) {
            return;
        }
        this.$watch(() => this.context.store.get(AppStoreKeys.APP_LOADER_SHOW, true), (n) => {
            clearTimeout(this.loaderTimeout);
            if (n === false) {
                this.show = false;
            } else {
                this.loaderTimeout = setTimeout(() => {
                    this.show = true;
                }, 500);
            }
        });
    }
};
