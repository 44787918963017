





























import Headline from '../ContentElement/Persona/Headline/Headline.vue';
import Logo from './Layout/FixedHeader/Components/Logo/Logo.vue';
import Btn from './Misc/Btn/Btn.vue';

export default {
    name: 'ErrorComponent',
    components: {Btn, Headline, Logo},
    props: {
        context: null,
        error: null
    },
    mounted()
    {
        console.error(this.error);
    }
};
