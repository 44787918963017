var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.realType !== "internal"
    ? _c(
        _vm.tag,
        _vm._g(
          _vm._b(
            {
              tag: "component",
              staticClass: "linkWrap",
              class: _vm.classes,
              attrs: { href: _vm.href },
            },
            "component",
            _vm.attr,
            false
          ),
          _vm.listeners
        ),
        [_vm._t("default")],
        2
      )
    : _c(
        "router-link",
        {
          staticClass: "linkWrap",
          class: _vm.classes,
          attrs: { tag: _vm.tag, to: _vm.href },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }