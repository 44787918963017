var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-tabs",
    _vm._g(
      _vm._b(
        {
          ref: "parent",
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.slots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "base-tabs",
        Object.assign({}, _vm.$props, _vm.$attrs),
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }