/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.04.14 at 16:08
 */

import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {getPath} from '@labor-digital/helferlein/lib/Lists/Paths/getPath';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isNumber} from '@labor-digital/helferlein/lib/Types/isNumber';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import {PidRepository} from '@labor-digital/typo3-vue-framework/lib/Core/Module/Spa/PidRepository';
import {AppStoreKeys} from '../AppStoreKeys';
import {MainMenuFirstLevelLink, MainMenuLink, MasterToggleLink} from '../Interface/Links';
import {LanguageSwitch} from './LanguageSwitch';

export class MenuService
{

    /**
     * The main context to resolve the requirements with
     */
    protected static _context: AppContext;

    /**
     * The pid of the currently selected homepage
     */
    protected static _homePid: number;

    /**
     * Initializes the service by providing the app context
     * @param context
     */
    public static initialize(context: AppContext): void
    {
        this._context = context;
    }

    /**
     * Lookup for the root line data
     */
    public static get rootLine(): Array<RootLineElementInterface>
    {
        return getPath(this._context, 'pageContext.rootLine', []);
    }

    /**
     * Lookup for the main menu
     */
    public static get mainMenu(): Array<PlainObject>
    {
        return getPath(this._context, 'pageContext.commonElements.mainMenu', []);
    }

    /**
     * Lookup for the main menu
     */
    public static get landingPageMenu(): Array<PlainObject>
    {
        return getPath(this._context, 'pageContext.data._state.landingpageMenuOverride', []);
    }

    /**
     * Get the current page layout
     */
    public static get pageLayout(): string
    {
        return getPath(this._context, 'pageContext.layout', []);
    }

    /**
     * Is it a landingpage?
     */
    public static get isLandingpage(): boolean
    {
        return this.pageLayout === '2';
    }

    /**
     * Header type can be shown, hidden or only the logo
     *
     * @returns show | hide | logo
     */
    public static get isLandingHeaderType(): 'show' | 'hide' | 'logo'
    {
        return this._context.pageContext.data.get('landingpageHeader', 'show');
    }

    /**
     * Returns the link object for the current page or null
     * if we are to early in the lifecycle to determine the page correctly
     */
    public static get currentPage(): MainMenuLink | null
    {
        const rootLine = this.rootLine;

        /**
         * Helper to dispatch a change event to the app store
         * This allows computed properties to watch changes on this getter reactively
         * @param page
         */
        const dispatchChanges = (page: MainMenuLink | null): MainMenuLink | null => {
            const store = this._context.store;
            const _currentPage = store.get(AppStoreKeys.CURRENT_PAGE);
            if (_currentPage?.id !== page?.id) {
                this._context.store.set(AppStoreKeys.CURRENT_PAGE, currentPage);
            }
            return page;
        };

        // Ignore early requests
        if (isEmpty(rootLine)) {
            return null;
        }

        // Special handling on a home page
        const homePid = this.getHomePid();
        let currentPage: MainMenuLink = null;
        if (parseInt(rootLine[rootLine.length - 1].id + '') === this.getHomePid()) {
            const links = this.getMasterToggleLinks();
            forEach(links, (link: MasterToggleLink) => {
                if (link.id !== homePid) {
                    return;
                }
                currentPage = link;
                return false;
            });

            return dispatchChanges(currentPage);
        }

        // Find the current page in the tree
        let mainMenuLayer = this.mainMenu;
        const depth = rootLine.length;
        let layer = 1;
        forEach(rootLine, (page: RootLineElementInterface) => {
            forEach(mainMenuLayer, (item: MainMenuLink) => {
                if (item.id === parseInt(page.id + '')) {

                    // Check if this is the last rootline element
                    if (layer === depth) {
                        currentPage = item;
                        return false;
                    }

                    // Ignore empty children
                    if (isEmpty(item.children)) {
                        return false;
                    }

                    // Go down further
                    mainMenuLayer = item.children;
                    return false;
                }
            });

            // Note: There might be a start page (pid: 1) in our root line
            // we skip it silently while gathering the current page
            layer++;

            // Break if we found the page
            if (currentPage !== null) {
                return false;
            }
        });

        return dispatchChanges(currentPage);
    }

    /**
     * Checks if the given PID exists in the root line
     * @param pid
     */
    public static isIdInRootLine(pid: number): boolean
    {
        let found = false;
        forEach(this.rootLine, (el: RootLineElementInterface) => {
            if (el.id != pid) {
                return;
            }
            found = true;
            return false;
        });
        return found;
    }

    /**
     * Returns the list of entries in the master toggle based on the the main menu object
     */
    public static getMasterToggleLinks(): Array<MasterToggleLink>
    {
        const companyPagePid = this.pidRepository.getPid('page.homeForCompanies');
        const applicantsPagePid = this.pidRepository.getPid('page.home');

        // Find for-companies link and children in the main menu
        let companyLink = '/';
        let companyChildren = [];
        forEach(this.mainMenu, (el: PlainObject) => {
            if (companyPagePid != el.id) {
                return;
            }
            companyLink = el.href;
            companyChildren = el.children;
            return false;
        });

        // Find for-applicants children in the main menu
        let applicantsChildren = [];
        const ignoredPids = [applicantsPagePid, companyPagePid, this.pidRepository.getPid('storage.meta')];
        forEach(this.mainMenu, (el: PlainObject) => {
            if (ignoredPids.indexOf(parseInt(el.id)) !== -1) {
                return;
            }
            applicantsChildren.push(el);
        });

        // Build the list
        return [
            {
                masterToggle: true,
                id: this.pidRepository.getPid('page.home'),
                title: 'navigation.toggle.forApplicants',
                href: '/',
                children: applicantsChildren,
                type: 'linkPage'
            },
            {
                masterToggle: true,
                id: companyPagePid,
                title: 'navigation.toggle.forCompanies',
                href: companyLink,
                children: companyChildren,
                type: 'linkPage'
            }
        ];
    }

    /**
     * Returns the link for the homepage based on the current root line.
     * Home is either / or /fuer-unternehmen based on the given path in the root line
     */
    public static getHomeLink(): string
    {
        const overwriteLink = this._context.pageContext.data.get('landingpageLogoLink');
        if (!isEmpty(overwriteLink)) {
            return overwriteLink.slug;
        }

        if (!LanguageSwitch.isMainLanguage()) {
            return this._context.pageContext.baseUrl;
        }

        let homePid = this.pidRepository.getPid('page.home');
        const hasHomePid = isNumber(this._homePid);
        const isHomePidValid = hasHomePid && homePid !== this._homePid && (
            this.isIdInRootLine(this._homePid) || this.isIdInRootLine(this.pidRepository.getPid('storage.meta'))
        );

        if (!isHomePidValid) {
            const companyPagePid = this.pidRepository.getPid('page.homeForCompanies');
            const isCompanyPage = this.isIdInRootLine(companyPagePid);
            homePid = isCompanyPage ? companyPagePid : this.pidRepository.getPid('page.home');
        } else {
            homePid = this._homePid;
        }

        let link = '/';
        forEach(this.getMasterToggleLinks(), (el: MasterToggleLink) => {
            if (el.id != homePid) {
                return;
            }
            this._homePid = el.id;
            link = el.href;
            return false;
        });
        return link;
    }

    public static filterIgnorePids(pids: Array<any> = [])
    {
        let children = [];
        const ignoredPids = [this.pidRepository.getPid('storage.meta'), ...pids];

        forEach(this.mainMenu, (el: PlainObject) => {
            if (ignoredPids.indexOf(parseInt(el.id)) !== -1) {
                return;
            }
            children.push(el);
        });

        return children;
    }

    /**
     * Builds the list of first level main menu items based on the current root line
     * @param hideBranchPages Set this to false to hide branch pages from the entries
     * @param isLandingpage
     */
    public static getMainMenuFirstLevel(hideBranchPages?: boolean, isLandingpage: boolean = false): Array<MainMenuFirstLevelLink>
    {
        if (!LanguageSwitch.isMainLanguage()) {
            return this.filterIgnorePids() as any;
        }

        const companyPagePid = this.pidRepository.getPid('page.homeForCompanies');
        const isCompanyPage = this.getHomeLink() !== '/';

        // Determine which kind of menu to build
        let children = [];
        if (this.isLandingpage) {
            forEach(this.landingPageMenu, (el: PlainObject) => {
                children.push(el);
            });

            return children;
        }

        if (isCompanyPage) {
            // Build for companies
            forEach(this.mainMenu, (el: PlainObject) => {
                if (companyPagePid != el.id) {
                    return;
                }
                if (hideBranchPages && el.id == this.pidRepository.getPid('page.branchListForCompanies')) {
                    return;
                }
                children = [...el.children];
                return false;
            });
            // Remove branches if required
            if (hideBranchPages) {
                const branchPagePid = this.pidRepository.getPid('page.branchListForCompanies');
                forEach(children, (el: PlainObject, k) => {
                    if (el.id != branchPagePid) {
                        return;
                    }
                    children.splice(k, 1);
                    return false;
                });
            }
            return children;
        }
        // Build for applicants
        if (hideBranchPages) {
            return this.filterIgnorePids([this.pidRepository.getPid('page.branchListForApplicants'), this.pidRepository.getPid('page.homeForCompanies')]);
        }
        return this.filterIgnorePids([this.pidRepository.getPid('page.homeForCompanies')]);
    }

    /**
     * Builds the list of first level meta menu items based on the current root line
     */
    public static getMetaMenu(): Array<PlainObject>
    {
        let menuClean = [];
        const metaPid = this.pidRepository.getPid('storage.meta');
        forEach(this.mainMenu, (v: PlainObject) => {
            if (v.id !== metaPid) {
                return;
            }
            menuClean = v.children;
            return false;
        });
        return menuClean;
    }

    /**
     * Finds the branch page definition for the currently selected page type
     */
    public static getMainMenuBranchPage(): MainMenuFirstLevelLink | PlainObject
    {
        const firstLevel = this.getMainMenuFirstLevel();
        const validPids = [
            this.pidRepository.getPid('page.branchListForCompanies'),
            this.pidRepository.getPid('page.branchListForApplicants')
        ];
        let branchPage = {};
        forEach(firstLevel, (el: PlainObject) => {
            if (validPids.indexOf(el.id) === -1) {
                return;
            }
            branchPage = el;
            return false;
        });
        return branchPage;
    }

    /**
     * Returns true if the current page is the homepage for applicants
     */
    public static isHomepageForApplicants(): boolean
    {
        if (this.currentPage === null) {
            return false;
        }

        return this.currentPage.id === this.pidRepository.getPid('page.home');
    }

    /**
     * Returns true if the current page is the homepage for applicants
     */
    public static isHomepageForCompanies(): boolean
    {
        if (this.currentPage === null) {
            return false;
        }

        return this.currentPage.id === this.pidRepository.getPid('page.homeForCompanies');
    }


    /**
     * Returns true if the current page is the homepage for applicants
     */
    public static isLanguageCode(lang: string): boolean
    {
        return this._context.translation.languageCode === lang;
    }


    /**
     * Returns true if the current page is the homepage for applicants
     */
    public static isHomepageForLandingpages(): boolean
    {
        const inRootLine = this.rootLine.filter(page => page.id === this.pidRepository.getPid('page.homeForLandingpages'));

        return !isEmpty(inRootLine);
    }

    /**
     * Returns true if the current page runs in the "applicants" mode
     */
    public static isForApplicants(): boolean
    {
        return !this.isForCompanies();
    }

    /**
     * Returns true if the current page runs in the "company" mode
     */
    public static isForCompanies(): boolean
    {
        return this.getHomeLink() !== '/' && LanguageSwitch.isMainLanguage();
    }

    /**
     * Helper to set the home pid from the master toggle element
     * @param pid
     */
    public static setHomePid(pid: number): void
    {
        this._homePid = pid;
    }

    /**
     * Returns the currently set home page id
     */
    public static getHomePid(): number
    {
        return isNumber(this._homePid) ? this._homePid : -1;
    }


    /**
     * Internal access helper to the pid repository
     */
    protected static get pidRepository(): PidRepository
    {
        return this._context.pageContext.pidRepository;
    }
}
