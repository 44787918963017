












































import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {getPath} from '@labor-digital/helferlein/lib/Lists/Paths/getPath';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {Hooper, Pagination as HooperPagination, Slide} from 'hooper';
import ImageTag from '../ImageTag/ImageTag.vue';

export default {
    name: 'ImageSlider',
    components: {ImageTag, Hooper, Slide, HooperPagination},
    props: {
        images: {
            type: Array,
            required: true
        },
        /**
         * A list of definition keys by the breakpoint short code
         * The short code is used as a max-width value
         * so {sm: small} will use the "small" definition for all window width up until
         * the max width of the sm break point. All other breakpoints will fall back to the
         * definition given as "definition"    prop
         */
        bpDefinitions: {
            Type: Object,
            default: null
        },
        /**
         * A imaging definition to process the image with
         * Can be overwritten by the bpDefinitions object
         */
        definition: {
            Type: String,
            default: ''
        },
        /**
         * A crop definition -> By default the "default" variant will be used
         */
        crop: {
            Type: String,
            default: ''
        },
        /**
         * controls hooper slider autoplay and duration
         */
        isAutoplay: {
            default: false,
            type: Boolean
        },
        slideDuration: {
            default: 7500,
            type: Number
        },
        /**
         * Set to empty or null if not needed
         */
        lazyLoading: {
            Type: String | null,
            default: 'lazy'
        }
    },
    computed: {
        hasMedia(): boolean
        {
            if (!isArray(this.images)) {
                return true;
            }
            return this.images.length === 0;
        },
        isSlider()
        {
            if (!isArray(this.images)) {
                return false;
            }
            return this.images.length > 1;
        },
        hasDesc()
        {
            if (this.isEmpty) {
                return false;
            }
            let hasDesc = false;
            forEach(this.images, image => {
                if (isEmpty(getPath(image, 'image.desc'))) {
                    return;
                }
                hasDesc = true;
                return false;
            });
            return hasDesc;
        }
    }
};
