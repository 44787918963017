/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.07.03 at 15:24
 */

import {isString} from '@labor-digital/helferlein';

export class HyphenFormatter
{
    public static hyphen(text: string): string
    {
        if (!isString(text)) {
            return '';
        }

        return text.replace(/(\w{3,99}?(ungs|ung|ers|gs|ts|er|en|ß))(.*)(?:\s|$)/mi, (a, b, c, d) => {
            if (d.length > 5) {
                d = this.hyphen(d);
            }
            return b + '&shy;' + d;
        });
    }
}
