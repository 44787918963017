/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.23 at 12:15
 */

import {getData} from '@labor-digital/helferlein/lib/Dom/getData';
import {ComponentProxy} from '@labor-digital/helferlein/lib/Entities/ComponentProxy';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';

export default {
    data()
    {
        return {
            iconHoverProxy: new ComponentProxy(this),
            iconHoverStates: {},
            iconHoverAfterUpdateTimeout: 0
        };
    },
    methods: {
        /**
         * Used build the props for the container that should trigger
         * the icon hover if the mouse enters/leaves it.
         *
         * It should be bound using v-bind="iconHoverContainerProps()"
         *
         * @param namespace Optional namespace if you have to handle multiple
         * icons/hover sets in a single component
         */
        iconHoverContainerProps(namespace?: string)
        {
            namespace = !isUndefined(namespace) ? namespace + '' : 'default';
            return {
                'data-icon-hover-listener': namespace,
                'data-icon-hover-listener-id': this._uid
            };
        },
        /**
         * Used to build the props for the icon component to be bound with
         * v-bind="iconHoverProps()"
         *
         * @param namespace Optional namespace if you have to handle multiple
         * icons/hover sets in a single component
         */
        iconHoverProps(namespace?: string): PlainObject
        {
            namespace = !isUndefined(namespace) ? namespace + '' : 'default';
            return {
                filled: this.iconHoverStates[namespace] ?? false
            };
        },

        /**
         * Mostly internal,
         * can be used to recollect the list of hoverable containers if the auto-detection failed
         */
        iconHoverForceUpdate()
        {
            // Ignore on ssr
            if (isUndefined(this.$el)) {
                return;
            }

            // Clear the timeout
            clearTimeout(this.iconHoverAfterUpdateTimeout);

            // Start the update handler
            this.iconHoverAfterUpdateTimeout = setTimeout(() => {
                // Clear the proxy
                this.iconHoverProxy.destroy();
                this.iconHoverProxy = new ComponentProxy(this);

                // Rebind the elements
                // Sadly we can't bind native events using the $listener object injection,
                // therefore we have to be a bit creative when it comes to binding
                // our event listeners to the dom objects. We can't use "refs" because
                // other components might also set the ref attribute and therefore we would
                // overwrite it. Therefore we do the workaround using the data attributes
                if (typeof this.$el.querySelectorAll !== 'undefined') {

                    const listenerContainers = [...this.$el.querySelectorAll('[data-icon-hover-listener]'), this.$el];

                    forEach(listenerContainers, (container: HTMLElement) => {
                        const listenerId = getData(container, 'icon-hover-listener-id');
                        if (listenerId !== this._uid) {
                            return;
                        }
                        const namespace = getData(container, 'icon-hover-listener');
                        if (isUndefined(namespace)) {
                            return;
                        }
                        this.iconHoverProxy.bind(container, 'mouseenter', () => {
                            this.$set(this.iconHoverStates, namespace, true);
                        });
                        this.iconHoverProxy.bind(container, 'mouseleave', () => {
                            this.$set(this.iconHoverStates, namespace, false);
                        });
                    });

                }
            }, 50);
        }
    },
    mounted()
    {
        this.iconHoverForceUpdate();
    },
    updated()
    {
        this.iconHoverForceUpdate();
    },
    destroy()
    {
        this.iconHoverProxy.destroy();
    }
};
