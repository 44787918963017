/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.04.07 at 19:59
 */

import {getOffset} from '@labor-digital/helferlein/lib/Dom/getOffset';
import {ComponentProxy} from '@labor-digital/helferlein/lib/Entities/ComponentProxy';
import {isBrowser} from '@labor-digital/helferlein/lib/Environment/isBrowser';
import {isObject} from '@labor-digital/helferlein/lib/Types/isObject';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {DirectiveOptions} from 'vue';

export const ButtonActiveGradient: DirectiveOptions = {
    bind(el, binding, vnode)
    {
        // Do this only in the browser
        if (!isBrowser()) {
            return;
        }
        
        // Create a proxy on the target element
        const proxy = (
            el as any
        ).$btnActEffProxy = new ComponentProxy(this);
        (
            el as any
        ).$btnActEffIsHover = binding.arg;
        
        // Animate the gradient when the mouse moves
        proxy.bind(el, 'mousemove', (e: MouseEvent) => {
            if ((
                el as any
            ).$btnActEffIsHover) {
                el.style.background = null;
                return;
            }
            
            // Prepare the offset
            let oX = e.offsetX;
            let oY = e.offsetY;
            
            // Check if the target is not the parent
            if (e.target !== el) {
                
                // Add all child offsets to the current event position
                let _el: HTMLElement = e.target as HTMLElement;
                for (var i = 0; i < 10; i++) {
                    if (_el == el) {
                        break;
                    }
                    if (!isObject(_el.parentElement)) {
                        break;
                    }
                    const off = getOffset(_el, _el.parentElement);
                    oY += off.top;
                    oX += off.left;
                    _el = _el.parentElement;
                }
            }
            
            // Update the background based on the calculated info
            el.style.background = 'radial-gradient(100px at ' + oX + 'px ' + oY + 'px, #2C8367, #64B32B)';
        });
        
        // Reset the background
        proxy.bind(el, 'mouseleave', (e) => {
            el.style.background = ''; // IE11 fallback to ignore styles because it doesn´t understand null
            el.style.background = null; // null for all browsers that are able to remove the styles completely
        });
    },
    update(el, binding)
    {
        (
            el as any
        ).$btnActEffIsHover = binding.arg;
    },
    unbind(el)
    {
        if (isUndefined((
            el as any
        ).$btnActEffProxy)) {
            return;
        }
        (
            el as any
        ).$btnActEffProxy.destroy();
    }
};
