



























import BaseCheckbox from '@labor-digital/vuetiful/src/Components/Checkbox/Checkbox.vue';
import Icon from '../../Misc/Icon/Icon.vue';

export default {
    name: 'Checkbox',
    components: {Icon, BaseCheckbox}
};
