import { render, staticRenderFns } from "./Featured.vue?vue&type=template&id=79cec552&scoped=true&"
import script from "./Featured.vue?vue&type=script&lang=ts&"
export * from "./Featured.vue?vue&type=script&lang=ts&"
import style0 from "./Featured.sass?vue&type=style&index=0&id=79cec552&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79cec552",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79cec552')) {
      api.createRecord('79cec552', component.options)
    } else {
      api.reload('79cec552', component.options)
    }
    module.hot.accept("./Featured.vue?vue&type=template&id=79cec552&scoped=true&", function () {
      api.rerender('79cec552', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Component/Layout/Search/Featured/Featured.vue"
export default component.exports