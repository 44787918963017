var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "greenContainer" }, [
    _c(
      "div",
      {
        staticClass: "greenContainer__outer",
        class: { "greenContainer__outer--container": _vm.addContainerStyles },
      },
      [
        _c(
          "div",
          { staticClass: "greenContainer__inner" },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }