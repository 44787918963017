var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobAlertModal" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.formSent
          ? _c("div", { key: "form", staticClass: "jobAlertModal__form" }, [
              _c("div", { staticClass: "jobAlertModal__col" }, [
                _c(
                  "div",
                  { staticClass: "jobAlertModal__box" },
                  [
                    _c(
                      "label",
                      { staticClass: "jobAlertModal__label" },
                      [
                        _c("icon", {
                          staticClass: "jobFilter__icon",
                          attrs: { icon: "maleCircle" },
                        }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("job.alert.modal.label")) +
                            "\n                    "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input-field", {
                      staticClass: "jobAlertModal__input",
                      attrs: {
                        placeholder: _vm.$t("job.alert.modal.name"),
                        error: _vm.nameError,
                        "read-only": _vm.isSending,
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                    _vm._v(" "),
                    _c("input-field", {
                      staticClass: "jobAlertModal__input",
                      attrs: {
                        placeholder: _vm.$t("job.alert.modal.surname"),
                        error: _vm.surnameError,
                        "read-only": _vm.isSending,
                      },
                      model: {
                        value: _vm.surname,
                        callback: function ($$v) {
                          _vm.surname = $$v
                        },
                        expression: "surname",
                      },
                    }),
                    _vm._v(" "),
                    _c("input-field", {
                      staticClass: "jobAlertModal__input",
                      attrs: {
                        placeholder: _vm.$t("job.alert.modal.email"),
                        type: "email",
                        "read-only": _vm.isSending,
                        error: _vm.mailError,
                        "email-validation-label": _vm.$t(
                          "job.alert.modal.error.mail"
                        ),
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "jobAlertModal__col" },
                [
                  _c(
                    "div",
                    { staticClass: "jobAlertModal__box" },
                    [
                      _c("job-alert-location", {
                        attrs: {
                          id: "job-alert-location",
                          error: _vm.locationError,
                          "read-only": _vm.isSending,
                          required: "",
                        },
                        model: {
                          value: _vm.location,
                          callback: function ($$v) {
                            _vm.location = $$v
                          },
                          expression: "location",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "jobAlertModal__box" },
                    [
                      _c("job-alert-occupation", {
                        attrs: {
                          id: "job-alert-occupation",
                          error: _vm.occupationError,
                          "read-only": _vm.isSending,
                        },
                        model: {
                          value: _vm.occupation,
                          callback: function ($$v) {
                            _vm.occupation = $$v
                          },
                          expression: "occupation",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("checkbox", {
                    staticClass: "jobAlertModal__checkbox",
                    attrs: {
                      items: _vm.confirmGdprOptions,
                      error: _vm.confirmGdprError,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function (props) {
                            return [
                              props.item.confirmGdpr
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "job.alert.modal.privatePolicy.yes.1"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                      _c(
                                        "link-wrap",
                                        {
                                          attrs: {
                                            link: _vm.privacyPolicyPage,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "job.alert.modal.privatePolicy.yes.2"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.$t(
                                              "job.alert.modal.privatePolicy.yes.3"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1093875325
                    ),
                    model: {
                      value: _vm.gdprModelBridge,
                      callback: function ($$v) {
                        _vm.gdprModelBridge = $$v
                      },
                      expression: "gdprModelBridge",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "Btn",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.send.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.isSending ? _c("Spinner") : _vm._e(),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("job.alert.modal.submit")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSending
                ? _c("div", { staticClass: "jobAlertModal__overlay" })
                : _vm._e(),
            ])
          : _c("div", { key: "formSent", staticClass: "jobAlertModal__box" }, [
              _c("span", { staticClass: "jobAlertModal__success" }, [
                _vm._v(_vm._s(_vm.$t("job.alert.modal.success", [_vm.email]))),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }