/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.19 at 18:41
 */

import {JobService} from '../../../../Service/JobService';

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    methods: {
        /**
         * Helper to emit the demand filter event
         */
        emitDemandFilterEvent()
        {
            this.$emit('demandFilter', JobService.demand);
        }
    },
    computed: {
        demand()
        {
            return JobService.demand;
        }
    }
};
