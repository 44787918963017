






















































import {isUndefined} from '@labor-digital/helferlein';
import {getBackgroundColor} from '@labor-digital/helferlein/lib/Color/getBackgroundColor';
import {rgbToHexColor} from '@labor-digital/helferlein/lib/Color/rgbToHexColor';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import Icon from '../Icon/Icon.vue';
import IconHoverMixin from '../Icon/IconHoverMixin';
import LinkWrap from '../Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'Btn',
    components: {Icon, LinkWrap},
    mixins: [IconHoverMixin],
    props: {
        identifier: {
            type: String,
            default: null
        },
        isGhost: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'link'
        },
        title: String,
        label: String,
        // A icon identifier for a static mapping using the Icon component
        icon: String,
        // If given the icon will be placed as background image
        iconUrl: String,
        // If given, the icon will be placed as svg inline in the html -> Overrides iconUrl
        iconSvgContent: String,
        iconPosition: String,
        href: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            isHover: false,
            extSvg: ''
        };
    },
    computed: {
        backgroundColor()
        {
            return rgbToHexColor(getBackgroundColor(this.$parent.$parent.$el));
        },
        linkType(): string
        {
            switch (this.type) {
                case 'email':
                    return 'mail';
                case 'phone':
                    // You can easily add new cases without providing a "return" for each one.
                    // This works:
                    // case 'foo':
                    // case 'bar':
                    // case 'baz':
                    return this.type;
                default:
                    return 'auto';
            }
        },
        hasIcon(): boolean
        {
            return !isEmpty(this.iconUrl) || !isEmpty(this.iconSvgContent) || this.useIconComponent;
        },
        useIconComponent(): boolean
        {
            return !isEmpty(this.icon);
        },
        styles()
        {
            return {
                backgroundImage: !isEmpty(this.iconUrl) ? 'url(' + this.iconUrl + ')' : null
            };
        },
        classes()
        {
            return {
                'btn--hover': this.isHover,
                'btn--default': !this.isGhost,
                'btn--ghost': this.isGhost,
                'btn--disabled': this.isDisabled,
                'btn--withoutLabel': this.iconPosition === 'withoutLabel'
            };
        },
        hasIconLeft()
        {
            return this.hasIcon && this.iconPosition === 'left' || this.iconPosition === 'withoutLabel';
        },
        hasIconRight()
        {
            return this.hasIcon && this.iconPosition === 'right';
        }
    },
    methods: {
        makeTitle()
        {
            if (!isEmpty(this.title)) {
                return this.title;
            }
            if (!isUndefined(this.$slots.default) && !isEmpty(this.$slots.default[0].text)) {
                return this.$slots.default[0].text.trim();
            }
            return '';
        }
    }
};
