
























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';

export default {
    name: 'Headline',
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            text: this.context.data.get('text'),
            tag: this.context.data.get('tag', 'h1'),
            id: this.context.data.get('id', '')
        };
    }
};
